import {Injectable} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, forkJoin, Observable, of, Subscription} from 'rxjs';
import {map, mergeMap, take} from 'rxjs/operators';
import {cloneDeep} from 'lodash';
import {
    AppHttpRequestsBetsnapsGamesUserBetCashoutRequest as UserBetCashoutRequest,
    AppHttpRequestsBetsnapsGamesUserBetPlaceBetBatchRequest as UserBetPlaceBetBatchRequest,
    AppHttpRequestsBetsnapsGamesUserBetPlaceBetRequest as UserBetPlaceBetRequest,
    AppHttpRequestsVendorGamesGameVendorGameParticipationCloseRequest as GameVendorGameParticipationCloseRequest,
    AppHttpRequestsVendorGamesGameVendorGameParticipationStartRequest as GameVendorGameParticipationStartRequest,
    AppHttpResponsesBetsnapsGamesBasicGameHttpResponse as BasicGameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameInvitationHttpResponse as GameInvitationHttpResponse,
    AppHttpResponsesBetsnapsGamesGameInvitationListHttpResponse as GameInvitationListHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserGroupHttpResponse as LeaderboardUserGroupHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse as LeaderboardUserHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserListHttpResponse as LeaderboardUserListHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserUpdateHttpResponse as LeaderboardUserUpdateHttpResponse,
    AppHttpResponsesBetsnapsGamesGameListHttpResponse as GameListHttpResponse,
    AppHttpResponsesBetsnapsGamesGameMatchHttpResponse as GameMatchHttpResponse,
    AppHttpResponsesBetsnapsGamesGameMatchListHttpResponse as GameMatchListHttpResponse,
    AppHttpResponsesBetsnapsGamesGameSettingHttpResponse as GameSettingHttpResponse,
    AppHttpResponsesBetsnapsGamesGameShareDataHttpResponse as GameShareDataHttpResponse,
    AppHttpResponsesBetsnapsGamesGameTenantHttpResponse as GameTenantHttpResponse,
    AppHttpResponsesBetsnapsGamesGameUserListHttpResponse as GameUserListHttpResponse,
    AppHttpResponsesBetsnapsGamesUserBetHttpResponse as UserBetHttpResponse,
    AppHttpResponsesBetsnapsGamesUserBetListHttpResponse as UserBetListHttpResponse,
    AppHttpResponsesBetsnapsGamesUserBetPlaceBetBatchHttpResponse as UserBetPlaceBetBatchResponse,
    AppHttpResponsesBetsnapsGamesUserBetPlaceBetReportHttpResponse as UserBetPlaceBetReportHttpResponse,
    AppHttpResponsesBetsnapsPrizeStructuresCalculatedPrizeListHttpResponse as CalculatedPrizeListHttpResponse,
    AppHttpResponsesBetsnapsRankingsSimpleTenantRankingListHttpResponse as SimpleTenantRankingListHttpResponse,
    AppHttpResponsesFriendsFriendHttpResponse as FriendHttpResponse,
    AppHttpResponsesSportsDataMatchHttpResponse as MatchHttpResponse,
    AppHttpResponsesSportsDataMatchMarketHttpResponse as MatchMarketHttpResponse,
    AppHttpResponsesSportsDataMatchMarketListHttpResponse as MatchMarketListHttpResponse,
    AppHttpResponsesSportsDataMatchMarketOutcomeHttpResponse as MatchMarketOutcomeHttpResponse,
    AppHttpResponsesSportsDataMatchMarketOutcomeUpdateHttpResponse as MatchMarketOutcomeUpdateHttpResponse,
    AppHttpResponsesSportsDataMatchMarketUpdateHttpResponse as MatchMarketUpdateHttpResponse,
    AppHttpResponsesTranslationsTranslationHttpResponse as TranslationHttpResponse,
    AppHttpResponsesUsersPlayerPublicHttpResponse as PlayerPublicHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameHttpResponse as GameVendorGameHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameListHttpResponse as GameVendorGameListHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationHttpResponse as GameVendorGameParticipationHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationListHttpResponse as GameVendorGameParticipationListHttpResponse,
    AppHttpResponsesVendorGamesGameVendorGameParticipationTransactionHttpResponse as GameVendorGameParticipationTransactionHttpResponse,
    AppHttpResponsesBetsnapsGamesUserBetHttpResponse as GameUserBetHttpResponse,
    GamesApi,
    PrizesApi,
    VendorGamesApi
} from '../../api';
import {
    AppEventsBetsnapsFeedBetCancelReceived as BetCancelReceived,
    AppEventsBetsnapsFeedBetSettlementReceived as BetSettlementReceived,
    AppEventsBetsnapsFeedBetStopReceived as BetStopReceived,
    AppEventsBetsnapsFeedFixtureChangeProcessed as FixtureChangeProcessed,
    AppEventsBetsnapsFeedMarketSettlementReceived as MarketSettlementReceived,
    AppEventsBetsnapsFeedOddsChangeReceived as OddsChangeReceived,
    AppEventsBetsnapsFeedRollbackBetCancelReceived as RollbackBetCancelReceived,
    AppEventsBetsnapsFeedRollbackBetSettlementReceived as RollbackBetSettlementReceived,
    AppEventsBetsnapsFeedRollbackMarketSettlementReceived as RollbackMarketSettlementReceived,
    AppEventsBetsnapsGamesAutoCashedOutAllActiveBets as AutoCashedOutAllActiveBetsEvent,
    AppEventsBetsnapsGamesAutoCanceledAllActiveBets as AutoCanceledAllActiveBets,
    AppEventsBetsnapsGamesAutoFailedAllPendingBets as AutoFailedAllPendingBetsEvent,
    AppEventsBetsnapsGamesAutoFailedAllPendingCashouts as AutoFailedAllPendingCashoutsEvent,
    AppEventsBetsnapsGamesBetConfirmed as BetConfirmedEvent,
    AppEventsBetsnapsGamesBetFailed as BetFailedEvent,
    AppEventsBetsnapsGamesCashoutConfirmed as CashoutConfirmedEvent,
    AppEventsBetsnapsGamesCashoutFailed as CashoutFailedEvent,
    AppEventsBetsnapsGamesGameLeaderboardChanged as GameLeaderboardChanged,
    AppEventsBetsnapsGamesGameLeaderboardTimestampChanged as GameLeaderboardTimestampChanged,
    AppEventsBetsnapsGamesGameMatchCancelled as GameMatchCancelledEvent,
    AppEventsBetsnapsGamesGameStateChanged as GameStateChanged,
    AppEventsBetsnapsGamesUserCashedOutBet as UserCashedOutBetEvent,
    AppEventsBetsnapsGamesUserJoinedGame as UserJoinedGameEvent,
    AppEventsBetsnapsGamesUserLeftGame as UserLeftGameEvent,
    AppEventsBetsnapsGamesUserPlacedBet as UserPlacedBetEvent,
    AppEventsVendorGamesCancelledGameVendorGameParticipations as CancelledGameVendorGameParticipations,
    AppEventsVendorGamesCancelledGameVendorGameParticipationTransactions as CancelledGameVendorGameParticipationTransactions,
    AppEventsVendorGamesClosedGameVendorGameParticipations as ClosedGameVendorGameParticipations,
    AppEventsVendorGamesGameVendorGameParticipationStateChanged as GameVendorGameParticipationStateChanged,
    AppEventsVendorGamesGameVendorGameParticipationTransactionStateChanged as GameVendorGameParticipationTransactionStateChanged,
    AppEventsVendorGamesGameVendorGameStateChanged as GameVendorGameStateChanged, GamePointsEngineEnum,
} from '../';
import {DbTranslationsPipe, OrdinalNumberPipe} from '../pipes';
import {
    GameBetPlacementOptionEnum,
    GameLeaderboardViewEnum,
    GameVendorGameParticipationStatusEnum,
    GameVendorGameStatusEnum,
    UserBetStateEnum
} from '../enums';
import {AuthenticationService} from './authentication.service';
import {MobiledetectService} from './mobiledetect.service';
import {TenantService} from './tenant.service';
import {BroadcastingService} from './broadcasting.service';
import {FriendsService} from './friends.service';
import {MyTranslateService} from './my-translate.service';
import {DebugService} from './debug.service';
import {LoggerService} from './logger.service';
import {ProfileService} from './profile.service';
import {ErrorService} from './error.service';
import * as moment from 'moment';
import {NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import {ConnectionEvents} from 'ngx-laravel-echo-jn';
import {MyNotificationsService} from './my-notifications.service';
import {FeedService} from './feed.service';
import {WidgetBet} from '../interfaces';
import {PlayerService} from './player.service';
import {btoaUnicode} from '../helpers';

@Injectable({
    providedIn: 'root'
})
export class BetsnapdetailService {

    private gameSubject = new BehaviorSubject<GameHttpResponse>(null);
    public game$ = this.gameSubject.asObservable();

    private gameShareDataSubject = new BehaviorSubject<GameShareDataHttpResponse>(null);
    public gameShareData$ = this.gameShareDataSubject.asObservable();
    private gameRequestRunning = false;

    private gameMatchesSubject = new BehaviorSubject<GameMatchHttpResponse[]>(null);
    public gameMatches$ = this.gameMatchesSubject.asObservable();
    private gameMatchesRequestRunning = false;

    private gameVendorGamesSubject = new BehaviorSubject<GameVendorGameHttpResponse[]>(null);
    public gameVendorGames$ = this.gameVendorGamesSubject.asObservable();
    private gameVendorGamesRequestRunning = false;

    private matchesMarketsSubject = new BehaviorSubject<MatchMarketHttpResponse[][]>([]);
    public matchesMarkets$ = this.matchesMarketsSubject.asObservable();

    private userBetsSubject = new BehaviorSubject<UserBetHttpResponse[][]>(null);
    public userBets$ = this.userBetsSubject.asObservable();
    private userBetsRequestRunning = false;
    private userBetsFetchedFromApi = false;

    private widgetBetsSubject = new BehaviorSubject<WidgetBet[]>(null);
    public widgetBets$ = this.widgetBetsSubject.asObservable();

    private gameVendorGameParticipationsSubject = new BehaviorSubject<GameVendorGameParticipationHttpResponse[]>(null);
    public gameVendorGameParticipations$ = this.gameVendorGameParticipationsSubject.asObservable();
    private gameVendorGameParticipationsRequestRunning = false;

    private gameUsersChangedSubject = new BehaviorSubject<boolean>(false);
    public gameUsersChanged$ = this.gameUsersChangedSubject.asObservable();

    private joinedGameListSubject = new BehaviorSubject<GameListHttpResponse>(null);
    public joinedGameList$ = this.joinedGameListSubject.asObservable();

    private prizeStructureSubject = new BehaviorSubject<CalculatedPrizeListHttpResponse>(null);
    public prizeStructure$ = this.prizeStructureSubject.asObservable();
    private leaderboardUserListSubject = new BehaviorSubject<LeaderboardUserListHttpResponse>(null);
    public leaderboardUserList$ = this.leaderboardUserListSubject.asObservable();
    private leaderboardFriendUserListSubject = new BehaviorSubject<LeaderboardUserListHttpResponse>(null);
    public leaderboardFriendUserList$ = this.leaderboardFriendUserListSubject.asObservable();
    private leaderBoardRequestRunning = false;
    public leaderBoardUserCompactViewLimit = 150;

    private leaderboardTimestampSubject = new BehaviorSubject<string>(null);
    public leaderboardTimestamp$ = this.leaderboardTimestampSubject.asObservable();

    private sentGameInvitationsSubject = new BehaviorSubject<GameInvitationHttpResponse[]>(null);
    public sentGameInvitations$ = this.sentGameInvitationsSubject.asObservable();

    private leaderBoardChangeEventSubscription: Subscription;
    private leaderBoardTimestampChangeEventSubscription: Subscription;
    private gameBroadcastEventSubscriptions: Subscription[] = [];
    private apiGetRequestSubscriptions: Subscription[] = [];
    private matchBroadcastEventSubscriptions: Array<{ matchId: number, subscriptions: Subscription[] }> = [];
    private gameVendorGameEventSubscriptions: Array<{ gameVendorGameId: number, subscriptions: Subscription[] }> = [];

    private _gameSettings: GameSettingHttpResponse;

    private placeBetDialogPendingBets = 0;
    private placeBetDialogBetPoints = 0.00;

    public h2hCompetitor: LeaderboardUserHttpResponse;
    public isTournament: boolean = true;

    public failedBets: UserBetHttpResponse[] = [];

    public rankingMenuActiveKey: string = 'all';

    private reconnectDataReloadAfterSeconds = 60;
    private lastDataReloadAfterReconnect = null;

    constructor(private authenticationService: AuthenticationService,
                private tenantService: TenantService,
                private mobileDetect: MobiledetectService,
                private gamesApi: GamesApi,
                private prizesApi: PrizesApi,
                private vendorGamesApi: VendorGamesApi,
                private broadcastingService: BroadcastingService,
                private metaService: Meta,
                private translateService: TranslateService,
                private myNotificationsService: MyNotificationsService,
                private translations: MyTranslateService,
                private friendsService: FriendsService,
                private profileService: ProfileService,
                private feedService: FeedService,
                public playerService: PlayerService,
                private debugService: DebugService,
                private loggerService: LoggerService,
                private errorService: ErrorService) {

        if (this.broadcastingService.echoService) {
            this.broadcastingService.echoService.rawConnectionState.subscribe(
                (connectionEvent: ConnectionEvents) => {
                    if (connectionEvent.type === 'reconnect' && this.game) {
                        if (this.lastDataReloadAfterReconnect === null ||
                            moment.duration(moment().utc().diff(this.lastDataReloadAfterReconnect)).asSeconds() > this.reconnectDataReloadAfterSeconds) {
                            this.lastDataReloadAfterReconnect = moment().utc();
                            this.debugService.writeMessageToConsoleLog('reload game data on socket reconnect');
                            this.abortRequestsAndUnsubscribeFromBroadcastEvents();
                            this.refreshGameData();
                        }
                    }
                });
        }
    }

    public getGameData(gameUniqueId: string, reset: boolean = false, handleGameStateChange: boolean = false, loadGameSettings: boolean = true, loadGameShareData: boolean = true, loadSentGameInvitations: boolean = true) {
        if (reset) {
            this.resetAllData();
        }

        this.gameRequestRunning = true;

        // get game data
        this.apiGetRequestSubscriptions.push(
            this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdGet(
                this.tenantService.tenantData.id,
                gameUniqueId,
                this.authenticationService.authToken
            ).pipe(take(1))
            .subscribe({
                next: (game: GameHttpResponse) => {
                    if (game) {
                        if (loadGameShareData) {
                            this.getGameShareData(game.game_unique_id);
                        }
                        if (loadSentGameInvitations) {
                            this.getSentGameInvitations(game);
                        }

                        if (game.sport_id !== 999) {
                            if (loadGameSettings) {
                                this.getGameSettings(game.game_unique_id);
                            }
                            this.checkForActivePlaceBetDialog(game);
                        }

                        this.gameSubject.next(game);
                        // Competition Type Tournament or BattleRoyal
                        this.isTournament = game.competition_type === 2 || game.competition_type === 5;

                        // load leaderboard with game in case of H2H
                        if (game.game_state > 2 && game.game_state !== 99 &&
                            this.rankingMenuActiveKey !== 'top' &&
                            (
                                (game.competition_type === 1 && this.authenticationService.currentUser) ||
                                (game.competition_type === 3 && game.is_current_user_joined)
                            )
                        ) {
                            this.getFullLeaderBoard(game);
                        }

                        if (this.gameBroadcastEventSubscriptions.length < 1 && game.game_state < 7) {
                            this.subscribeToGameBroadcastingEvents(game);
                        }
                        if (game.game_state === 3) {
                            this.subscribeForLeaderboardEvents(game);
                        }

                        if (handleGameStateChange) {
                            this.handleGameStateChange(null, game);
                        }

                        this.gameRequestRunning = false;
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.gameRequestRunning = false;
                    this.errorService.handleHttpErrorResponse(err);
                }
            })
        );
    }

    private subscribeForLeaderboardEvents(game) {
        // check if tenant should subscribe to full leaderboard broadcasts or only update timestamps
        if (this.tenantService.tenantData.configuration.broadcast_game_leaderboard) {
            this.subscribeForLeaderboardChange(game);
        } else {
            this.subscribeForLeaderboardTimestampChange(game);
        }
    }

    private getGameSettings(gameUniqueId: string) {
        this.apiGetRequestSubscriptions.push(
            this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdSettingsGet(
                this.tenantService.tenantData.id,
                gameUniqueId
            ).pipe(take(1))
            .subscribe({
                next:(gameSettings: GameSettingHttpResponse) => {
                    if (gameSettings) {
                        this._gameSettings = gameSettings;
                    }
                },
                error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
            })
        );
    }

    private getGameShareData(gameUniqueId: string) {
        this.apiGetRequestSubscriptions.push(
            this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdSharedataGet(
                this.tenantService.tenantData.id,
                gameUniqueId
            ).pipe(take(1))
            .subscribe({
                next: (gameShareData: GameShareDataHttpResponse) => {
                    if (gameShareData) {
                        this.gameShareDataSubject.next(gameShareData);
                        this.setGameShareMeta(gameUniqueId);
                    }
                },
                error:(err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
            })
        );
    }

    public get game(): GameHttpResponse {
        return this.gameSubject.value;
    }

    public get gameShareData(): GameShareDataHttpResponse {
        return this.gameShareDataSubject.value;
    }

    public get gameSettings(): GameSettingHttpResponse {
        return this._gameSettings;
    }

    public getSentGameInvitations(game: GameHttpResponse) {
        if (this.authenticationService.currentUser && game.game_state < 3) {
            this.apiGetRequestSubscriptions.push(
                this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdSentgameinvitationsGet(
                    this.tenantService.tenantData.id,
                    game.game_unique_id,
                    this.authenticationService.currentUser.id
                ).pipe(take(1))
                .subscribe({
                    next: (gameInvitationListHttpResponse: GameInvitationListHttpResponse) => {
                        if (gameInvitationListHttpResponse) {
                            this.sentGameInvitationsSubject.next(gameInvitationListHttpResponse.results);
                        }
                    },
                    error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
                })
            );
        }
    }

    public get sentGameInvitations(): GameInvitationHttpResponse[] {
        return this.sentGameInvitationsSubject.value;
    }

    private setGameShareMeta(gameUniqueId: string) {

        const gameShareData = this.gameShareDataSubject.getValue();
        const game_name = this.getShareGameName(gameShareData.game);

        this.metaService.updateTag({
            content: game_name,
            name: 'title'
        });
        this.metaService.updateTag({
            content: moment(gameShareData.game.start_date).local().format(this.tenantService.tenantData.internationalization.date_time_format),
            name: 'description'
        });
        this.metaService.updateTag({
            content: game_name,
            property: 'og:title'
        });
        this.metaService.updateTag({
            content: moment(gameShareData.game.start_date).local().format(this.tenantService.tenantData.internationalization.date_time_format),
            property: 'og:description'
        });
        this.metaService.updateTag({
            content: this.tenantService.getTenantDomainWithDefaultRelativePath() + '/betsnapdetail/' + gameUniqueId + '/details',
            property: 'og:url'
        });
        this.metaService.updateTag({
            content: gameShareData.game_share_img_url,
            property: 'og:image'
        });
    }

    private getShareGameName(game: BasicGameHttpResponse): string {
        const gameName = new DbTranslationsPipe(this.authenticationService).transform(game.game_name, 'game_name', game.translations);
        if (game.clone_number && game.clone_number > 0) {
            const currentGameTenant = game.game_tenants.find(
                (gameTenant: GameTenantHttpResponse) => (gameTenant.tenant_id === this.tenantService.tenantData.id)
            );

            if (currentGameTenant && currentGameTenant.allow_join_multiple_game_clones) {
                return gameName + ' (' + game.clone_number + ')';
            }
        }

        return gameName;
    }

    public joinGame(game: GameHttpResponse, userId: number, subscribeForGameNotifications: boolean): Observable<LeaderboardUserHttpResponse> {
        if (subscribeForGameNotifications) {
            return this.joinGameWithGameNotifications(game, userId);
        } else {
            return this.joinGameWithOutGameNotifications(game, userId);
        }
    }

    private joinGameWithGameNotifications(game: GameHttpResponse, userId: number): Observable<LeaderboardUserHttpResponse> {
        return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdJoinPost(
            this.tenantService.tenantData.id,
            game.game_unique_id,
            userId
        ).pipe(
            mergeMap((leaderboardUser: LeaderboardUserHttpResponse) => {
                return this.profileService.subscribeForGameNotifications(
                    game.game_unique_id,
                    userId
                ).pipe(
                    map(() => {
                        this.game.game_notifications_subscribed = true;
                        this.reloadDataAfterJoin(game, userId);
                        this.gameUsersChangedSubject.next(true);
                        return leaderboardUser;
                    })
                );
            })
        );
    }

    private joinGameWithOutGameNotifications(game: GameHttpResponse, userId: number): Observable<LeaderboardUserHttpResponse> {
        return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdJoinPost(
            this.tenantService.tenantData.id,
            game.game_unique_id,
            userId
        ).pipe(
            map((leaderboardUser: LeaderboardUserHttpResponse) => {
                this.reloadDataAfterJoin(game, userId);
                this.gameUsersChangedSubject.next(true);
                return leaderboardUser;
            })
        );
    }


    private reloadDataAfterJoin(game: GameHttpResponse, userId: number) {
        this.getGameData(game.game_unique_id);
        this.getPrizeStructure(game, true);
        if (this.joinedGameListSubject.value) {
            const currentJoinedGameList = this.joinedGameListSubject.getValue();
            currentJoinedGameList.results.push(game);
            this.joinedGameListSubject.next(currentJoinedGameList);
        } else {
            this.getJoinedGameList(
                userId
            );
        }

        if (game.sport_id === 999) {
            // only for casino games
            this.subscribeToGameVendorGameParticipationsBroadcastingEvents();
        } else {
            // only for games with matches and bets
            this.subscribeToUserBetEvents(game);
        }
    }

    public leaveGame(game: GameHttpResponse, userId: number): Observable<LeaderboardUserHttpResponse> {
        let apiMethodName = 'apiTenantsTenantIdGamesGameUniqueIdUsersUserIdLeaveDelete';

        if (this.mobileDetect.browserName() === 'operamini') {
            apiMethodName = 'apiOperaminideleteTenantsTenantIdGamesGameUniqueIdUsersUserIdLeavePost';
        }

        return this.gamesApi[apiMethodName](
            this.tenantService.tenantData.id,
            game.game_unique_id,
            userId
        ).pipe(
            map((leaderboardUser: LeaderboardUserHttpResponse) => {
                this.userBetsSubject.next(null);
                this.getGameData(game.game_unique_id);
                this.getPrizeStructure(game, true);
                if (this.joinedGameListSubject.value) {
                    const currentJoinedGameList = this.joinedGameListSubject.getValue();
                    currentJoinedGameList.results = currentJoinedGameList.results.filter(
                        (gameInList: GameHttpResponse) => gameInList.game_unique_id !== game.game_unique_id);
                    this.joinedGameListSubject.next(currentJoinedGameList);
                } else {
                    this.getJoinedGameList(
                        userId
                    );
                }
                this.gameUsersChangedSubject.next(true);

                // clear local storage
                if (localStorage.getItem('verification-redirect-url')) {
                    localStorage.removeItem('verification-redirect-url');
                }
                if (localStorage.getItem('widgetBets-' + game.game_unique_id)) {
                    localStorage.removeItem('widgetBets-' + game.game_unique_id);
                }
                if (localStorage.getItem('widgetBets-force')) {
                    localStorage.removeItem('widgetBets-force');
                }


                return leaderboardUser;
            })
        );
    }

    public unsubscribeFromGameNotifications(gameUniqueId: string, userId: number, unsubscribeSignature: string): Observable<any> {
        return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdNotificationsUnsubscribeGet(
            this.tenantService.tenantData.id,
            gameUniqueId,
            userId,
            unsubscribeSignature
        );
    }

    private checkForActivePlaceBetDialog(game: GameHttpResponse) {
        if (game.game_user) {
            if (this.placeBetDialogBetPoints > 0) {
                game.game_user.user_game_points -= this.placeBetDialogBetPoints;
            }

            if (this.placeBetDialogPendingBets > 0) {
                game.current_user_bet_count += this.placeBetDialogPendingBets;
            }
        }
    }

    public setPlaceBetDialogBetPoints(betPoints: number) {
        this.placeBetDialogBetPoints = betPoints;
    }

    public addPlaceBetDialogPendingBet(value: number = 1) {
        this.placeBetDialogPendingBets += value;
    }

    public removePlaceBetDialogPendingBet(value: number = 1) {
        this.placeBetDialogPendingBets -= value;
    }

    public substractFromUserGamePoints(value: number) {
        if (this.game && this.game.game_user) {
            const currentGame = this.game;
            currentGame.game_user.user_game_points -= value;
            this.gameSubject.next(currentGame);
        }
    }

    public addToUserGamePoints(value: number) {
        if (this.game && this.game.game_user) {
            const currentGame = this.game;
            currentGame.game_user.user_game_points += value;
            this.gameSubject.next(currentGame);
        }
    }

    public updateUserGamePoints(value: number) {
        if (this.game && this.game.game_user) {
            const currentGame = this.game;
            currentGame.game_user.user_game_points = value;
            this.gameSubject.next(currentGame);
        }
    }

    private raiseUserBetCount(value: number = 1) {
        if (this.game) {
            const currentGame = this.game;
            currentGame.current_user_bet_count += value;
            this.gameSubject.next(currentGame);
        }
    }

    public reduceUserBetCount(value: number = 1) {
        if (this.game && this.game.current_user_bet_count > 0) {
            const currentGame = this.game;
            currentGame.current_user_bet_count -= value;
            this.gameSubject.next(currentGame);
        }
    }

    public raiseUserOpenBetCount(value: number = 1) {
        if (this.game) {
            const currentGame = this.game;
            currentGame.current_user_open_bet_count += value;
            this.gameSubject.next(currentGame);
        }
    }

    public reduceUserOpenBetCount(value: number = 1) {
        if (this.game && this.game.current_user_open_bet_count > 0) {
            const currentGame = this.game;
            currentGame.current_user_open_bet_count -= value;
            this.gameSubject.next(currentGame);
        }
    }

    public getGameMatches(gameUniqueId: string, forceReload: boolean = false) {

        if ((!this.gameMatchesSubject.value || forceReload) && !this.gameMatchesRequestRunning) {
            this.gameMatchesRequestRunning = true;
            this.apiGetRequestSubscriptions.push(
                this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdMatchesGet(
                    this.tenantService.tenantData.id,
                    gameUniqueId
                ).pipe(take(1))
                .subscribe({
                    next: (gameMatchList: GameMatchListHttpResponse) => {
                        // default market check
                        gameMatchList.results = gameMatchList.results.map(
                            (gameMatch: GameMatchHttpResponse) => {
                                // remove default market if market status 0
                                if (gameMatch.match.default_market) {
                                    if (gameMatch.match.default_market.outcomes) {
                                        gameMatch.match.default_market.outcomes = this.filterMatchMarketOutcomes(gameMatch.match.default_market.outcomes);
                                    }
                                    if ((gameMatch.match.default_market.market_status !== 1 && gameMatch.match.default_market.market_status !== -1) ||
                                        !gameMatch.match.default_market.outcomes ||
                                        gameMatch.match.default_market.outcomes.length === 0) {
                                        gameMatch.match.default_market = null;
                                    } else {
                                        // if default market exists substract from special market count
                                        gameMatch.match.markets_count -= 1;
                                    }
                                }
                                return gameMatch;
                            }
                        );
                        this.gameMatchesSubject.next(gameMatchList.results);
                        if (gameMatchList.results.length > 0) {
                            this.subscribeToMatchBroadcastingEvents();
                        }
                        this.gameMatchesRequestRunning = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.gameMatchesRequestRunning = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                })
            );
        }
    }

    private subscribeToMatchBroadcastingEvents() {
        if (this.game && this.gameMatchesSubject.getValue()) {
            const currentMatches = this.gameMatchesSubject.getValue();

            currentMatches.forEach(
                (gameMatch: GameMatchHttpResponse) => {
                    this.matchBroadcastEventSubscriptions[gameMatch.game_match_id] = {
                        matchId: gameMatch.match_id,
                        subscriptions: []
                    };

                    this.broadcastingService.joinChannel('Match.' + gameMatch.match_id);

                    if (this.game.game_state < 5 ||
                        (this.game.game_state === 99 &&
                            (gameMatch.match.status === 'not_started' || gameMatch.match.status === 'scheduled' ||
                                gameMatch.match.status === 'live')
                        )
                    ) {
                        // Listen for OddChange events
                        this.matchBroadcastEventSubscriptions[gameMatch.game_match_id].subscriptions.push(
                            this.broadcastingService.listenOnEventInChannel('Match.' + gameMatch.match_id, 'Betsnaps\\Feed\\OddsChangeReceived')
                                .subscribe((broadcastEventData: OddsChangeReceived) => {
                                    if (broadcastEventData && broadcastEventData.matchId === gameMatch.match_id) {
                                        this.debugService.writeMessageToConsoleLog('ODD CHANGE - Match: ' + gameMatch.match_id);
                                        this.handleOddChange(broadcastEventData);
                                    }
                                })
                        );

                        // Listen for CashoutApiOddChange events
                        this.matchBroadcastEventSubscriptions[gameMatch.game_match_id].subscriptions.push(
                            this.broadcastingService.listenOnEventInChannel('Match.' + gameMatch.match_id, 'Betsnaps\\Feed\\CashoutApiOddsChangeReceived')
                                .subscribe((broadcastEventData: OddsChangeReceived) => {
                                    if (broadcastEventData && broadcastEventData.matchId === gameMatch.match_id) {
                                        this.debugService.writeMessageToConsoleLog('CASHOUT API ODD CHANGE - Match: ' + gameMatch.match_id);
                                        this.handleOddChange(broadcastEventData);
                                    }
                                })
                        );
                    }
                    if (this.game.game_state < 5) {
                        // Listen for BetStop events
                        this.matchBroadcastEventSubscriptions[gameMatch.game_match_id].subscriptions.push(
                            this.broadcastingService.listenOnEventInChannel('Match.' + gameMatch.match_id, 'Betsnaps\\Feed\\BetStopReceived')
                                .subscribe((broadcastEventData: BetStopReceived) => {
                                    if (broadcastEventData && broadcastEventData.matchId === gameMatch.match_id) {
                                        this.debugService.writeMessageToConsoleLog('BET STOP - Match: ' + gameMatch.match_id);
                                        this.handleBetStop(broadcastEventData.matchId);
                                    }
                                })
                        );
                        // Listen for MarketSettlement events
                        this.matchBroadcastEventSubscriptions[gameMatch.game_match_id].subscriptions.push(
                            this.broadcastingService.listenOnEventInChannel('Match.' + gameMatch.match_id, 'Betsnaps\\Feed\\MarketSettlementReceived')
                                .subscribe((broadcastEventData: MarketSettlementReceived) => {
                                    if (broadcastEventData && broadcastEventData.matchId === gameMatch.match_id) {
                                        this.debugService.writeMessageToConsoleLog('MARKET SETTLEMENT - Match: ' + gameMatch.match_id);
                                        this.removeReceivedMarkets(broadcastEventData, -3);
                                    }
                                })
                        );
                        // Listen for BetSettlement events
                        this.matchBroadcastEventSubscriptions[gameMatch.game_match_id].subscriptions.push(
                            this.broadcastingService.listenOnEventInChannel('Match.' + gameMatch.match_id, 'Betsnaps\\Feed\\BetSettlementReceived')
                                .subscribe((broadcastEventData: BetSettlementReceived) => {
                                    if (broadcastEventData && broadcastEventData.matchId === gameMatch.match_id) {
                                        this.debugService.writeMessageToConsoleLog('BET SETTLEMENT - Match: ' + gameMatch.match_id);
                                        this.reloadUserBetsAndGameDataIfNecessary(broadcastEventData, [1, -2]);
                                    }
                                })
                        );

                        // Listen for RollbackMarketSettlement events
                        this.matchBroadcastEventSubscriptions[gameMatch.game_match_id].subscriptions.push(
                            this.broadcastingService.listenOnEventInChannel('Match.' + gameMatch.match_id, 'etsnaps\\Feed\\RollbackMarketSettlementReceived')
                                .subscribe((broadcastEventData: RollbackMarketSettlementReceived) => {
                                    if (broadcastEventData && broadcastEventData.matchId === gameMatch.match_id) {
                                        this.debugService.writeMessageToConsoleLog('ROLLBACK MARKET SETTLEMENT - Match: ' + gameMatch.match_id);
                                        this.addReceivedMarkets(broadcastEventData, 0);
                                    }
                                })
                        );

                        // Listen for RollbackBetSettlement events
                        this.matchBroadcastEventSubscriptions[gameMatch.game_match_id].subscriptions.push(
                            this.broadcastingService.listenOnEventInChannel('Match.' + gameMatch.match_id, 'Betsnaps\\Feed\\RollbackBetSettlementReceived')
                                .subscribe((broadcastEventData: RollbackBetSettlementReceived) => {
                                    if (broadcastEventData && broadcastEventData.matchId === gameMatch.match_id) {
                                        this.debugService.writeMessageToConsoleLog('ROLLBACK BET SETTLEMENT - Match: ' + gameMatch.match_id);
                                        this.reloadUserBetsAndGameDataIfNecessary(broadcastEventData, [2]);
                                    }
                                })
                        );

                        // Listen for BetCancel events
                        this.matchBroadcastEventSubscriptions[gameMatch.game_match_id].subscriptions.push(
                            this.broadcastingService.listenOnEventInChannel('Match.' + gameMatch.match_id, 'Betsnaps\\Feed\\BetCancelReceived')
                                .subscribe((broadcastEventData: BetCancelReceived) => {
                                    if (broadcastEventData && broadcastEventData.matchId === gameMatch.match_id) {
                                        this.debugService.writeMessageToConsoleLog('BET CANCEL - Match: ' + gameMatch.match_id);
                                        this.reloadUserBetsAndGameDataIfNecessary(broadcastEventData, [1, -2]);
                                    }
                                })
                        );

                        // Listen for RollbackBetCancel events
                        this.matchBroadcastEventSubscriptions[gameMatch.game_match_id].subscriptions.push(
                            this.broadcastingService.listenOnEventInChannel('Match.' + gameMatch.match_id, 'Betsnaps\\Feed\\RollbackBetCancelReceived')
                                .subscribe((broadcastEventData: RollbackBetCancelReceived) => {
                                    if (broadcastEventData && broadcastEventData.matchId === gameMatch.match_id) {
                                        this.debugService.writeMessageToConsoleLog('ROLLBACK BET CANCEL - Match: ' + gameMatch.match_id);
                                        this.reloadUserBetsAndGameDataIfNecessary(broadcastEventData, [3]);
                                    }
                                })
                        );
                        // Listen for FixtureChange events
                        this.matchBroadcastEventSubscriptions[gameMatch.game_match_id].subscriptions.push(
                            this.broadcastingService.listenOnEventInChannel('Match.' + gameMatch.match_id, 'Betsnaps\\Feed\\FixtureChangeProcessed')
                                .subscribe((broadcastEventData: FixtureChangeProcessed) => {
                                    if (broadcastEventData && broadcastEventData.matchId === gameMatch.match_id && broadcastEventData.matchHttpResponse) {
                                        this.debugService.writeMessageToConsoleLog('FIXTURE CHANGE - Match: ' + gameMatch.match_id);
                                        this.updateMatch(broadcastEventData.matchHttpResponse);
                                    }
                                })
                        );
                    }
                }
            );
        }
    }

    public getGameVendorGames(gameUniqueId: string, forceReload: boolean = false) {
        if ((!this.gameVendorGamesSubject.value || forceReload) && !this.gameVendorGamesRequestRunning) {
            this.gameVendorGamesRequestRunning = true;
            this.apiGetRequestSubscriptions.push(
                this.vendorGamesApi.apiTenantsTenantIdGamesGameUniqueIdVendorgamesGet(
                    this.tenantService.tenantData.id,
                    gameUniqueId
                ).pipe(take(1))
                .subscribe({
                    next: (gameVendorGameList: GameVendorGameListHttpResponse) => {
                        this.gameVendorGamesSubject.next(gameVendorGameList.results);
                        if (this.gameVendorGameEventSubscriptions.length < 1) {
                            this.subscribeToGameVendorGameBroadcastingEvents();
                        }
                        this.gameVendorGamesRequestRunning = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.gameVendorGamesRequestRunning = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                })
            );
        }
    }

    private subscribeToGameVendorGameBroadcastingEvents() {
        if (this.game && this.gameVendorGamesSubject.getValue()) {
            const currentGameVendorGames = this.gameVendorGamesSubject.getValue();

            currentGameVendorGames.forEach(
                (gameVendorGame: GameVendorGameHttpResponse) => {
                    if (this.game.game_state < 5 &&
                        gameVendorGame.status !== GameVendorGameStatusEnum.CLOSED &&
                        gameVendorGame.status !== GameVendorGameStatusEnum.CANCELLED) {

                        this.broadcastingService.joinChannel('GameVendorGame.' + gameVendorGame.id);

                        this.gameVendorGameEventSubscriptions[gameVendorGame.id] = {
                            gameVendorGameId: gameVendorGame.id,
                            subscriptions: []
                        };

                        this.gameVendorGameEventSubscriptions[gameVendorGame.id].subscriptions.push(
                            this.broadcastingService.listenOnEventInChannel('GameVendorGame.' + gameVendorGame.id, 'VendorGames\\GameVendorGameStarted')
                                .subscribe((broadcastEventData: GameVendorGameStateChanged) => {
                                    if (broadcastEventData) {
                                        this.debugService.writeMessageToConsoleLog('GameVendorGameStarted - ' + gameVendorGame.id);
                                        this.handleGameVendorGameStateChange(broadcastEventData);
                                    }
                                })
                        );

                        this.gameVendorGameEventSubscriptions[gameVendorGame.id].subscriptions.push(
                            this.broadcastingService.listenOnEventInChannel('GameVendorGame.' + gameVendorGame.id, 'VendorGames\\GameVendorGameFinished')
                                .subscribe((broadcastEventData: GameVendorGameStateChanged) => {
                                    if (broadcastEventData) {
                                        this.debugService.writeMessageToConsoleLog('GameVendorGameFinished - ' + gameVendorGame.id);
                                        this.handleGameVendorGameStateChange(broadcastEventData);
                                    }
                                })
                        );

                        this.gameVendorGameEventSubscriptions[gameVendorGame.id].subscriptions.push(
                            this.broadcastingService.listenOnEventInChannel('GameVendorGame.' + gameVendorGame.id, 'VendorGames\\GameVendorGameClosed')
                                .subscribe((broadcastEventData: GameVendorGameStateChanged) => {
                                    if (broadcastEventData) {
                                        this.debugService.writeMessageToConsoleLog('GameVendorGameClosed - ' + gameVendorGame.id);
                                        this.handleGameVendorGameStateChange(broadcastEventData);
                                    }
                                })
                        );

                        this.gameVendorGameEventSubscriptions[gameVendorGame.id].subscriptions.push(
                            this.broadcastingService.listenOnEventInChannel('GameVendorGame.' + gameVendorGame.id, 'VendorGames\\GameVendorGameCancelled')
                                .subscribe((broadcastEventData: GameVendorGameStateChanged) => {
                                    if (broadcastEventData) {
                                        this.debugService.writeMessageToConsoleLog('GameVendorGameCancelled - ' + gameVendorGame.id);
                                        this.handleGameVendorGameStateChange(broadcastEventData);
                                    }
                                })
                        );
                    }
                }
            );
        }
    }

    private handleGameVendorGameStateChange(broadcastEventData: GameVendorGameStateChanged) {
        const receivedGameVendorGame = broadcastEventData.gameVendorGameHttpResponse;

        const currentGameVendorGames = this.gameVendorGamesSubject.getValue();
        const updatedGameVendorGames = currentGameVendorGames.map(
            (gameVendorGame: GameVendorGameHttpResponse) => {
                if (gameVendorGame.id === receivedGameVendorGame.id) {
                    const newGameVendorGame = gameVendorGame;
                    newGameVendorGame.status = receivedGameVendorGame.status;
                    newGameVendorGame.updated_at = receivedGameVendorGame.updated_at;
                    return newGameVendorGame;
                } else {
                    return gameVendorGame;
                }
            }
        );
        this.gameVendorGamesSubject.next(updatedGameVendorGames);

    }

    public getGameVendorGameParticipations(gameUniqueId: string, userId: number, forceReload: boolean = false) {
        if ((!this.gameVendorGameParticipationsSubject.value || forceReload) && !this.gameVendorGameParticipationsRequestRunning) {
            this.gameVendorGameParticipationsRequestRunning = true;

            this.apiGetRequestSubscriptions.push(
                this.vendorGamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdGamevendorgameparticipationsGet(
                    this.tenantService.tenantData.id,
                    gameUniqueId,
                    userId,
                    undefined,
                    true
                ).pipe(take(1))
                .subscribe({
                    next:(gameVendorGameParticipationList: GameVendorGameParticipationListHttpResponse) => {
                        this.gameVendorGameParticipationsSubject.next(gameVendorGameParticipationList.results);
                        this.gameVendorGameParticipationsRequestRunning = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.gameVendorGameParticipationsRequestRunning = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                })
            );
        }
    }

    private subscribeToGameVendorGameParticipationsBroadcastingEvents() {

        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this.authenticationService.currentUser.id, 'VendorGames\\GameVendorGameParticipationStarted')
                .subscribe((broadcastEventData: GameVendorGameParticipationStateChanged) => {
                    if (broadcastEventData && this.game && this.gameVendorGameParticipationsSubject.getValue()) {
                        this.debugService.writeMessageToConsoleLog('GameVendorGameParticipationStarted - ' + broadcastEventData.gameVendorGameParticipationHttpResponse.id);
                        this.substractFromUserGamePoints(broadcastEventData.gameVendorGameParticipationHttpResponse.entry_points);
                        this.addGameVendorGameParticipation(broadcastEventData.gameVendorGameParticipationHttpResponse);
                    }
                })
        );

        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this.authenticationService.currentUser.id, 'VendorGames\\GameVendorGameParticipationClosed')
                .subscribe((broadcastEventData: GameVendorGameParticipationStateChanged) => {
                    if (broadcastEventData && this.game && this.gameVendorGameParticipationsSubject.getValue()) {
                        this.debugService.writeMessageToConsoleLog('GameVendorGameParticipationClosed - ' + broadcastEventData.gameVendorGameParticipationHttpResponse.id);
                        this.updateGameVendorGameParticipation(broadcastEventData.gameVendorGameParticipationHttpResponse);
                        this.addToUserGamePoints(broadcastEventData.gameVendorGameParticipationHttpResponse.game_user_points);
                    }
                })
        );

        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this.authenticationService.currentUser.id, 'VendorGames\\GameVendorGameParticipationTransactionStarted')
                .subscribe((broadcastEventData: GameVendorGameParticipationTransactionStateChanged) => {
                    if (broadcastEventData && this.game && this.gameVendorGameParticipationsSubject.getValue()) {
                        this.debugService.writeMessageToConsoleLog('GameVendorGameParticipationTransactionStarted - ' + broadcastEventData.gameVendorGameParticipationTransactionHttpResponse.id);
                        this.updateGameVendorGameParticipation(broadcastEventData.gameVendorGameParticipationHttpResponse);
                        this.addGameVendorGameParticipationTransaction(broadcastEventData.gameVendorGameParticipationTransactionHttpResponse);
                    }
                })
        );

        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this.authenticationService.currentUser.id, 'VendorGames\\GameVendorGameParticipationTransactionClosed')
                .subscribe((broadcastEventData: GameVendorGameParticipationTransactionStateChanged) => {
                    if (broadcastEventData && this.game && this.gameVendorGameParticipationsSubject.getValue()) {
                        this.debugService.writeMessageToConsoleLog('GameVendorGameParticipationTransactionClosed - ' + broadcastEventData.gameVendorGameParticipationTransactionHttpResponse.id);
                        this.updateGameVendorGameParticipation(broadcastEventData.gameVendorGameParticipationHttpResponse);
                        this.updateGameVendorGameParticipationTransaction(broadcastEventData.gameVendorGameParticipationTransactionHttpResponse);
                    }
                })
        );

        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + this.game.game_unique_id, 'VendorGames\\ClosedGameVendorGameParticipations')
                .subscribe((broadcastEventData: ClosedGameVendorGameParticipations) => {
                    if (broadcastEventData && this.game && this.gameVendorGameParticipationsSubject.getValue()) {
                        this.debugService.writeMessageToConsoleLog('ClosedGameVendorGameParticipations');
                        this.reloadVendorGameParticipationsAndGameDataIfNecessary(broadcastEventData);

                        this.translateService.get(['GENERAL.GAMEVENDORGAMEPARTICIPATION.NOTIFICATION.closed_all'])
                            .pipe(take(1)).subscribe(
                            translation => {
                                this.myNotificationsService.createNotificationToast('', translation['GENERAL.GAMEVENDORGAMEPARTICIPATION.NOTIFICATION.closed_all'], NotificationType.Success);
                            });
                    }
                })
        );

        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + this.game.game_unique_id, 'VendorGames\\CancelledGameVendorGameParticipations')
                .subscribe((broadcastEventData: CancelledGameVendorGameParticipations) => {
                    if (broadcastEventData && this.game && this.gameVendorGameParticipationsSubject.getValue()) {
                        this.debugService.writeMessageToConsoleLog('CancelledGameVendorGameParticipations');
                        this.reloadVendorGameParticipationsAndGameDataIfNecessary(broadcastEventData);

                        this.translateService.get(['GENERAL.GAMEVENDORGAMEPARTICIPATION.NOTIFICATION.cancelled_all'])
                            .pipe(take(1)).subscribe(
                            translation => {
                                this.myNotificationsService.createNotificationToast('', translation['GENERAL.GAMEVENDORGAMEPARTICIPATION.NOTIFICATION.cancelled_all'], NotificationType.Warn);
                            });
                    }
                })
        );

        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + this.game.game_unique_id, 'VendorGames\\CancelledGameVendorGameParticipationTransactions')
                .subscribe((broadcastEventData: CancelledGameVendorGameParticipationTransactions) => {
                    if (broadcastEventData && this.game && this.gameVendorGameParticipationsSubject.getValue()) {
                        this.debugService.writeMessageToConsoleLog('CancelledGameVendorGameParticipationTransactions');
                        this.reloadVendorGameParticipationsAndGameDataIfNecessary(broadcastEventData);

                        this.translateService.get(['GENERAL.GAMEVENDORGAMEPARTICIPATIONTRANSACTION.NOTIFICATION.cancelled_all'])
                            .pipe(take(1)).subscribe(
                            translation => {
                                this.myNotificationsService.createNotificationToast('', translation['GENERAL.GAMEVENDORGAMEPARTICIPATIONTRANSACTION.NOTIFICATION.cancelled_all'], NotificationType.Warn);
                            });
                    }
                })
        );
    }

    private reloadVendorGameParticipationsAndGameDataIfNecessary(broadcastEventData: ClosedGameVendorGameParticipations | CancelledGameVendorGameParticipations | CancelledGameVendorGameParticipationTransactions) {
        if (this.authenticationService.currentUser &&
            this.game && this.game.is_current_user_joined && this.game.game_state !== 99 && this.game.game_unique_id === broadcastEventData.gameUniqueId) {
            const currentGameVendorGameParticipations = this.gameVendorGameParticipationsSubject.getValue();
            // check for active participations
            if (currentGameVendorGameParticipations) {
                let activeGameVendorGameParticipations;
                if (broadcastEventData.vendorGameId) {
                    activeGameVendorGameParticipations = currentGameVendorGameParticipations.filter(
                        (gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) => (
                            (gameVendorGameParticipation.status === GameVendorGameParticipationStatusEnum.ACTIVE || gameVendorGameParticipation.status === GameVendorGameParticipationStatusEnum.INACTIVE) &&
                            gameVendorGameParticipation.vendor_game_id === broadcastEventData.vendorGameId
                        )
                    );
                } else {
                    activeGameVendorGameParticipations = currentGameVendorGameParticipations.filter(
                        (gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) => (
                            gameVendorGameParticipation.status === GameVendorGameParticipationStatusEnum.ACTIVE || gameVendorGameParticipation.status === GameVendorGameParticipationStatusEnum.INACTIVE
                        )
                    );
                }
                if (activeGameVendorGameParticipations && activeGameVendorGameParticipations.length > 0) {
                    this.getGameData(this.game.game_unique_id);
                    this.getGameVendorGameParticipations(this.game.game_unique_id, this.authenticationService.currentUser.id, true);
                }
            }
        }
    }

    public startGameVendorGameParticipation(gameUniqueId: string, vendorGameId: number, entryPoints: number): Observable<GameVendorGameParticipationHttpResponse> {

        const requestBody: GameVendorGameParticipationStartRequest = {
            vendor_game_id: vendorGameId,
            entry_points: entryPoints
        };

        return this.vendorGamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdGamevendorgameparticipationsPost(
            this.tenantService.tenantData.id,
            gameUniqueId,
            this.authenticationService.currentUser.id,
            requestBody
        ).pipe(map(
            (gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) => {
                this.addGameVendorGameParticipation(gameVendorGameParticipation);

                this.translateService.get(['GENERAL.GAMEVENDORGAMEPARTICIPATION.NOTIFICATION.started'])
                    .pipe(take(1)).subscribe(
                    translation => {
                        this.myNotificationsService.createNotificationToast('', translation['GENERAL.GAMEVENDORGAMEPARTICIPATION.NOTIFICATION.started'], NotificationType.Success);
                    });

                return gameVendorGameParticipation;
            }
        ));
    }

    public getGameVendorGameParticipation(gameUniqueId: string, participationId: number): Observable<GameVendorGameParticipationHttpResponse> {
        return this.vendorGamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdGamevendorgameparticipationsParticipationIdGet(
            this.tenantService.tenantData.id,
            gameUniqueId,
            this.authenticationService.currentUser.id,
            participationId
        );
    }

    private addGameVendorGameParticipation(gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) {
        if (!this.gameVendorGameParticipationsRequestRunning) {
            let currentGameVendorGameParticipations = this.gameVendorGameParticipationsSubject.getValue();
            if (!currentGameVendorGameParticipations) {
                currentGameVendorGameParticipations = [];
            }
            const participationInList = currentGameVendorGameParticipations.find((gameVendorGameParticipationInList: GameVendorGameParticipationHttpResponse) =>
                gameVendorGameParticipationInList.id === gameVendorGameParticipation.id
            );
            if (!participationInList) {
                currentGameVendorGameParticipations.push(gameVendorGameParticipation);
                this.gameVendorGameParticipationsSubject.next(currentGameVendorGameParticipations);
            }
        }
    }

    private addGameVendorGameParticipationTransaction(gameVendorGameParticipationTransaction: GameVendorGameParticipationTransactionHttpResponse) {
        if (!this.gameVendorGameParticipationsRequestRunning) {
            let currentGameVendorGameParticipations = this.gameVendorGameParticipationsSubject.getValue();
            currentGameVendorGameParticipations = currentGameVendorGameParticipations.map(
                (gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) => {
                    if (gameVendorGameParticipationTransaction.game_vendor_game_participation_id === gameVendorGameParticipation.id) {
                        if (!gameVendorGameParticipation.game_vendor_game_participation_transactions) {
                            gameVendorGameParticipation.game_vendor_game_participation_transactions = [];
                        }
                        gameVendorGameParticipation.game_vendor_game_participation_transactions.push(gameVendorGameParticipationTransaction);
                    }
                    return gameVendorGameParticipation;
                }
            );
            this.gameVendorGameParticipationsSubject.next(currentGameVendorGameParticipations);
        }
    }

    private updateGameVendorGameParticipationTransaction(gameVendorGameParticipationTransaction: GameVendorGameParticipationTransactionHttpResponse) {
        if (!this.gameVendorGameParticipationsRequestRunning) {
            let currentGameVendorGameParticipations = this.gameVendorGameParticipationsSubject.getValue();
            currentGameVendorGameParticipations = currentGameVendorGameParticipations.map(
                (gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) => {
                    if (gameVendorGameParticipationTransaction.game_vendor_game_participation_id === gameVendorGameParticipation.id) {
                        gameVendorGameParticipation.game_vendor_game_participation_transactions = gameVendorGameParticipation.game_vendor_game_participation_transactions.map(
                            (gameVendorGameParticipationTransactionInList: GameVendorGameParticipationTransactionHttpResponse) => {
                                if (gameVendorGameParticipationTransactionInList.id === gameVendorGameParticipationTransaction.id) {
                                    return gameVendorGameParticipationTransaction;
                                } else {
                                    return gameVendorGameParticipationTransactionInList;
                                }
                            }
                        );
                    }
                    return gameVendorGameParticipation;
                }
            );
            this.gameVendorGameParticipationsSubject.next(currentGameVendorGameParticipations);
        }
    }

    public closeGameVendorGameParticipation(gameUniqueId: string, participationId: number, gameUserPoints: number): Observable<GameVendorGameParticipationHttpResponse> {
        const requestBody: GameVendorGameParticipationCloseRequest = {
            game_user_points: gameUserPoints
        };

        return this.vendorGamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdGamevendorgameparticipationsParticipationIdClosePut(
            this.tenantService.tenantData.id,
            gameUniqueId,
            this.authenticationService.currentUser.id,
            participationId,
            requestBody
        ).pipe(map(
            (gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) => {
                this.updateGameVendorGameParticipation(gameVendorGameParticipation);

                this.translateService.get(['GENERAL.GAMEVENDORGAMEPARTICIPATION.NOTIFICATION.closed'])
                    .pipe(take(1)).subscribe(
                    translation => {
                        this.myNotificationsService.createNotificationToast('', translation['GENERAL.GAMEVENDORGAMEPARTICIPATION.NOTIFICATION.closed'], NotificationType.Success);
                    });

                return gameVendorGameParticipation;
            }
        ));
    }

    private updateGameVendorGameParticipation(gameVendorGameParticipation: GameVendorGameParticipationHttpResponse) {
        if (!this.gameVendorGameParticipationsRequestRunning) {
            let currentGameVendorGameParticipations = this.gameVendorGameParticipationsSubject.getValue();
            if (currentGameVendorGameParticipations) {
                currentGameVendorGameParticipations = currentGameVendorGameParticipations.map(
                    (gameVendorGameParticipationInList: GameVendorGameParticipationHttpResponse) => {
                        if (gameVendorGameParticipationInList.id === gameVendorGameParticipation.id) {
                            gameVendorGameParticipation.game_url = gameVendorGameParticipationInList.game_url;
                            gameVendorGameParticipation.vendor_game = gameVendorGameParticipationInList.vendor_game;
                            gameVendorGameParticipation.game_vendor_game_participation_transactions = gameVendorGameParticipationInList.game_vendor_game_participation_transactions;
                            return gameVendorGameParticipation;
                        } else {
                            return gameVendorGameParticipationInList;
                        }
                    }
                );
                this.gameVendorGameParticipationsSubject.next(currentGameVendorGameParticipations);
            }
        }
    }

    public getMatchMarkets(gameUniqueId: string, matchId: number, forceReload: boolean = false) {
        let currentMatchMarkets = this.matchesMarketsSubject.getValue();
        if (forceReload || !currentMatchMarkets[matchId]) {
            currentMatchMarkets[matchId] = [];
            this.apiGetRequestSubscriptions.push(
                this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsGet(
                    this.tenantService.tenantData.id,
                    gameUniqueId,
                    matchId
                ).pipe(take(1))
                .subscribe({
                    next: (matchMarketList: MatchMarketListHttpResponse) => {
                        if (matchMarketList) {
                            currentMatchMarkets[matchId] = matchMarketList.results;
                            this.matchesMarketsSubject.next(currentMatchMarkets);
                        }
                    },
                    error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
                })
            );
        }
    }

    public getGamePlayers(gameUniqueId: string, perPage?: number, page?: number, onlyFriends?: boolean): Observable<GameUserListHttpResponse> {
        return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersGet(
            this.tenantService.tenantData.id,
            gameUniqueId,
            perPage,
            page,
            null,
            onlyFriends,
            this.authenticationService.authToken
        );
    }

    public getGameTenantRankings(tenantId: number, gameUnqiueId: string): Observable<SimpleTenantRankingListHttpResponse> {
        return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdRelatedtenantrankingsGet(
            tenantId,
            gameUnqiueId
        );
    }

    public getJoinedGameList(userId: number) {
        this.apiGetRequestSubscriptions.push(
            this.gamesApi.apiTenantsTenantIdGamesGet(
                this.tenantService.tenantData.id,
                1000,
                1,
                undefined,
                '1,2,3', // published, runable, running
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                userId,
                this.authenticationService.authToken
            ).pipe(take(1))
            .subscribe({
                next: (gameHttpListResponse: GameListHttpResponse) => {
                    this.joinedGameListSubject.next(gameHttpListResponse);
                },
                error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
            })
        );
    }

    public getPrizeStructure(game: GameHttpResponse, forceReload: boolean = false) {
        if (!this.prizeStructureSubject.value || forceReload) {
            const prizePool = (game.is_guaranteed) ? game.prize_pool : game.current_prize_pool;
            if (prizePool > 0 || game.is_gift_game === 1 || game.competition_type !== 2 || forceReload) {
                this.apiGetRequestSubscriptions.push(
                    this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdPrizesGet(
                        this.tenantService.tenantData.id,
                        game.game_unique_id
                    ).pipe(take(1))
                    .subscribe({
                        next: (calculatedPrizeList: CalculatedPrizeListHttpResponse) => {
                            this.prizeStructureSubject.next(calculatedPrizeList);
                        },
                        error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
                    })
                );
            }
        }
    }

    public shouldRenderCompactLeaderBoard(game: GameHttpResponse): boolean {
        return (
            game.is_current_user_joined && game.game_state > 2 && game.game_state < 6 &&
            ((game.competition_type === 2 &&
            game.users_count > this.leaderBoardUserCompactViewLimit) ||
            (game.competition_type === 5))
        );
    }

    public getFullLeaderBoard(game: GameHttpResponse, view: string = GameLeaderboardViewEnum.DEFAULT, forceReload: boolean = false) {
        if (!this.leaderBoardRequestRunning && (!this.leaderboardUserListSubject.value || forceReload)) {

            if (forceReload) {
                this.loggerService.currentEventKey = 'updateLeaderboardRequest-' + game.game_unique_id;
                this.loggerService.startMeasurement('total');
                this.loggerService.startMeasurement('reloadLeaderboard');
                this.debugService.writeMessageToConsoleLog(
                    'reloadLeaderboard start: ' + moment().utc().format('YYYY-MM-DD HH:mm:ss.SSS')
                );
            } else {
                this.loggerService.currentEventKey = 'initialLeaderboardRequest-' + game.game_unique_id;
                this.loggerService.startMeasurement('total');
                this.loggerService.startMeasurement('getLeaderboard');
                this.debugService.writeMessageToConsoleLog(
                    'getLeaderboard start: ' + moment().utc().format('YYYY-MM-DD HH:mm:ss.SSS')
                );
            }

            // get leaderboard
            let groupId = null;
            if (game.is_current_user_joined && game.competition_type === 3 && game.game_state > 2) {
                if (game.game_user.game_user_group) {
                    groupId = game.game_user.game_user_group.group_id;
                }
            }


            this.leaderBoardRequestRunning = true;
            this.apiGetRequestSubscriptions.push(
                this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdLeaderboardGet(
                    this.tenantService.tenantData.id,
                    game.game_unique_id,
                    groupId,
                    undefined,
                    view ? view : undefined
                ).pipe(take(1))
                .subscribe({
                    next: (leaderboardUserList: LeaderboardUserListHttpResponse) => {

                        if (forceReload) {
                            this.loggerService.endMeasurement('reloadLeaderboard');
                            if (this.loggerService.getMeasurement('reloadLeaderboard')) {
                                this.debugService.writeMessageToConsoleLog(
                                    'reloadLeaderboard end: ' + this.loggerService.getMeasurement('reloadLeaderboard').endDate +
                                    ' (' + this.loggerService.getMeasurement('reloadLeaderboard').duration + ')'
                                );
                            }
                        } else {
                            this.loggerService.endMeasurement('getLeaderboard');
                            if (this.loggerService.getMeasurement('getLeaderboard')) {
                                this.debugService.writeMessageToConsoleLog(
                                    'getLeaderboard end: ' + this.loggerService.getMeasurement('getLeaderboard').endDate +
                                    ' (' + this.loggerService.getMeasurement('getLeaderboard').duration + ')'
                                );
                            }
                        }

                        // H2H
                        if (game.is_current_user_joined && (game.competition_type === 1 || game.competition_type === 3) && game.game_state > 2) {
                            this.setH2HCompetitor(leaderboardUserList.results, game.competition_type);
                        }

                        this.loggerService.startMeasurement('forwardLeaderboardDataToComponent');
                        this.leaderboardUserListSubject.next(leaderboardUserList);

                        if (game.game_state === 3) {
                            this.subscribeForLeaderboardEvents(game);
                        }

                        if (forceReload && view === GameLeaderboardViewEnum.COMPACT) {
                            this.loadFriendLeaderboardUsers(game)
                                .pipe(take(1)).subscribe(
                                (leaderboardUserList: LeaderboardUserListHttpResponse) => {
                                    if (leaderboardUserList) {
                                        this.storePublicPlayersFromLeaderboardUserslist(leaderboardUserList);
                                        this.leaderboardFriendUserListSubject.next(leaderboardUserList);
                                    }
                                });
                        }

                        this.storePublicPlayersFromLeaderboardUserslist(leaderboardUserList);

                        this.leaderboardTimestampSubject.next(null);
                        this.leaderBoardRequestRunning = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.leaderBoardRequestRunning = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                })
            );
        }
    }

    public storePublicPlayersFromLeaderboardUserslist(leaderboardUserList: LeaderboardUserListHttpResponse): void {
        //Only if game is running
        if (this.game.game_state === 3) {
            this.playerService.storePublicPlayersFromLeaderboardUserslist(leaderboardUserList);
        }
    }

    public loadTopRankedLeaderboardUsers(game: GameHttpResponse): Observable<LeaderboardUserListHttpResponse> {
        return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdLeaderboardGet(
            this.tenantService.tenantData.id,
            game.game_unique_id,
            undefined,
            undefined,
            'topranked'
        );
    }

    public loadFriendLeaderboardUsers(game: GameHttpResponse): Observable<LeaderboardUserListHttpResponse> {
        return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdLeaderboardGet(
            this.tenantService.tenantData.id,
            game.game_unique_id,
            undefined,
            true
        );
    }

    public getPaginatedLeaderBoard(game: GameHttpResponse, perPage?: number, page?: number, onlyFriends: boolean = false, reset: boolean = false): Observable<LeaderboardUserListHttpResponse> {
        // reset previous one if necessary
        if (reset) {
            this.leaderboardUserListSubject.next(null);
        }

        // get paginated leaderboard
        let groupId;
        if (game.is_current_user_joined && game.competition_type === 3 && game.game_state > 2) {
            if (game.game_user.game_user_group) {
                groupId = game.game_user.game_user_group.group_id;
            }
        }
        return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdLeaderboardGet(
            this.tenantService.tenantData.id,
            game.game_unique_id,
            groupId ? groupId : undefined,
            onlyFriends,
            undefined,
            perPage ? perPage : undefined,
            page ? page : undefined
        ).pipe(map(
            (leaderboardUserList: LeaderboardUserListHttpResponse) => {

                // H2H
                if (game.is_current_user_joined && (game.competition_type === 1 || game.competition_type === 3) && game.game_state > 2) {
                    this.setH2HCompetitor(leaderboardUserList.results, game.competition_type);
                }

                this.playerService.storePublicPlayersFromLeaderboardUserslist(leaderboardUserList);

                if (this.leaderboardUserListSubject.value) {
                    const currentLeaderBoardUserList = this.leaderboardUserListSubject.value;
                    const updatedLeaderBoardUserList = {
                        ...currentLeaderBoardUserList,
                        results: [...currentLeaderBoardUserList.results, ...leaderboardUserList.results],
                        count: currentLeaderBoardUserList.results.length + leaderboardUserList.results.length,
                    };
                    this.leaderboardUserListSubject.next(updatedLeaderBoardUserList);
                } else {
                    this.leaderboardUserListSubject.next(leaderboardUserList);
                }

                return leaderboardUserList;
            }
        ));
    }

    private subscribeForLeaderboardChange(game: GameHttpResponse) {
        if (!this.leaderBoardChangeEventSubscription) {

            this.broadcastingService.joinChannel('GameDetail.' + game.game_unique_id + '.Leaderboard');

            this.leaderBoardChangeEventSubscription =
                this.broadcastingService.listenOnEventInChannel('GameDetail.' + game.game_unique_id + '.Leaderboard', 'Betsnaps\\Games\\GameLeaderboardChanged')
                    .subscribe((broadcastEventData: GameLeaderboardChanged) => {
                        if (broadcastEventData) {

                            this.debugService.writeMessageToConsoleLog('LEADERBOARD CHANGED');

                            this.debugService.writeMessageToConsoleLog(
                                'leaderboardBroadcast received: ' + moment().utc().format('YYYY-MM-DD HH:mm:ss.SSS')
                            );
                            this.loggerService.currentEventKey = 'broadcastLeaderboard-processed-' + game.game_unique_id;
                            this.loggerService.startMeasurement('total');

                            const gameLeaderboardUserUpdateHttpResponses = broadcastEventData.gameLeaderboardUserUpdateHttpResponses;
                            let gameLeaderboardUserHttpResponses = this.buildGameLeaderboardUserHttpResponseFromUpdateResponses(gameLeaderboardUserUpdateHttpResponses);
                            const originalGameLeaderboardUserHttpResponses = gameLeaderboardUserHttpResponses;

                            // PooledH2H
                            if (game.is_current_user_joined && game.competition_type === 3 && game.game_state > 2) {
                                gameLeaderboardUserHttpResponses = this.reduceListToGameUserGroupCompetitors(game, gameLeaderboardUserHttpResponses, broadcastEventData);
                            }

                            // H2H
                            if (game.is_current_user_joined && (game.competition_type === 1 || game.competition_type === 3) && game.game_state > 2) {
                                this.setH2HCompetitor(gameLeaderboardUserHttpResponses, game.competition_type);
                            }

                            if (this.leaderboardUserListSubject.value) {
                                this.loggerService.startMeasurement('forwardLeaderboardDataToComponent');

                                const currentLeaderBoardUserList = this.leaderboardUserListSubject.value;
                                if (game.competition_type === 5) {
                                    const newLeaderboardUserList = this.getBattleRoyalCompactLeaderboard(gameLeaderboardUserHttpResponses);

                                    if (newLeaderboardUserList.length > 0) {
                                        currentLeaderBoardUserList.results = newLeaderboardUserList;
                                    }
                                } else {
                                    currentLeaderBoardUserList.results = gameLeaderboardUserHttpResponses;
                                }

                                currentLeaderBoardUserList.count = currentLeaderBoardUserList.results.length;
                                this.leaderboardUserListSubject.next(currentLeaderBoardUserList);
                            }

                            if (this.authenticationService.currentUser && game.is_current_user_joined) {
                                const currentUserIndex = broadcastEventData.gameLeaderboardUserAccess[this.authenticationService.currentUser.id];
                                // set game user object of current user
                                const currentGame = this.game;
                                if (currentGame.game_user) {
                                    const currentUserGamePoints = currentGame.game_user.user_game_points;
                                    currentGame.game_user = originalGameLeaderboardUserHttpResponses[currentUserIndex];
                                    currentGame.game_user.user_game_points = currentUserGamePoints;
                                    this.gameSubject.next(currentGame);
                                }
                            }
                        }
                    });
        }
    }

    private buildGameLeaderboardUserHttpResponseFromUpdateResponses(gameLeaderboardUserUpdateHttpResponses: LeaderboardUserUpdateHttpResponse[]): LeaderboardUserHttpResponse[] {

        const gameLeaderboardUserHttpResponses: Array<LeaderboardUserHttpResponse> = [];
        const gamePoints = this.game.game_points;

        gameLeaderboardUserUpdateHttpResponses.forEach((gameLeaderboardUserUpdateHttpResponse: LeaderboardUserUpdateHttpResponse) => {

            let tenantPrizeTranslations = null;
            if (gameLeaderboardUserUpdateHttpResponse.tenant_prize && gameLeaderboardUserUpdateHttpResponse.tenant_prize.title_translations) {
                tenantPrizeTranslations = this.buildTranslationHttpResponsesFromMinimizedTranslationsString(gameLeaderboardUserUpdateHttpResponse.tenant_prize.title_translations, 'title');
                delete gameLeaderboardUserUpdateHttpResponse.tenant_prize.title_translations;
            }

            let tenantPrizeTemplateTranslations = null;
            if (gameLeaderboardUserUpdateHttpResponse.tenant_prize_template && gameLeaderboardUserUpdateHttpResponse.tenant_prize_template.title_translations) {
                tenantPrizeTemplateTranslations = this.buildTranslationHttpResponsesFromMinimizedTranslationsString(gameLeaderboardUserUpdateHttpResponse.tenant_prize_template.title_translations, 'title');
                delete gameLeaderboardUserUpdateHttpResponse.tenant_prize_template.title_translations;
            }

            let gameUserGroup: LeaderboardUserGroupHttpResponse = null;
            if (gameLeaderboardUserUpdateHttpResponse.game_user_group) {

                const userGroup = gameLeaderboardUserUpdateHttpResponse.game_user_group;

                let groupTenantPrizeTranslations = null;
                if (userGroup.tenant_prize && userGroup.tenant_prize.title_translations) {
                    groupTenantPrizeTranslations = this.buildTranslationHttpResponsesFromMinimizedTranslationsString(userGroup.tenant_prize.title_translations, 'title');
                    delete userGroup.tenant_prize.title_translations;
                }

                let groupTenantPrizeTemplateTranslations = null;
                if (userGroup.tenant_prize_template && userGroup.tenant_prize_template.title_translations) {
                    groupTenantPrizeTemplateTranslations = this.buildTranslationHttpResponsesFromMinimizedTranslationsString(userGroup.tenant_prize_template.title_translations, 'title');
                    delete userGroup.tenant_prize_template.title_translations;
                }

                gameUserGroup = {
                    id: null, // ID not provided in updateResponse, not necessary
                    game_unique_id: this.game.game_unique_id,
                    user_id: gameLeaderboardUserUpdateHttpResponse.user_id,
                    group_id: userGroup.group_id,
                    rank: userGroup.rank ?? 0,
                    rank_change_down: userGroup.rank_change_down ?? 0,
                    rank_change_up: userGroup.rank_change_up ?? 0,
                    points_in_lead: userGroup.points_in_lead ?? 0,
                    points_to_first: userGroup.points_to_first ?? 0,
                    points_to_prizes: userGroup.points_to_prizes ?? 0,
                    prize_amount: userGroup.prize_amount ?? 0,
                    tenant_prize_id: userGroup.tenant_prize_id ?? null,
                    tenant_prize: userGroup.tenant_prize ?? null,
                    tenant_prize_template: userGroup.tenant_prize_template ?? null,
                    is_winner: (userGroup.is_winner) ? '1' : '0'
                };

                if (groupTenantPrizeTranslations) {
                    gameUserGroup.tenant_prize.translations = groupTenantPrizeTranslations;
                }
                if (groupTenantPrizeTemplateTranslations) {
                    gameUserGroup.tenant_prize_template.translations = groupTenantPrizeTemplateTranslations;
                }
            }

            const publicPlayer = this.playerService.getStoredPublicPlayer(gameLeaderboardUserUpdateHttpResponse.user_id);

            const leaderboardUser: LeaderboardUserHttpResponse = {
                id: null, // ID not provided in updateResponse, not necessary
                game_unique_id: this.game.game_unique_id,
                user_id: gameLeaderboardUserUpdateHttpResponse.user_id,
                user_game_points: gameLeaderboardUserUpdateHttpResponse.user_game_points,
                total_score: gameLeaderboardUserUpdateHttpResponse.total_score,
                highest_point: gameLeaderboardUserUpdateHttpResponse.highest_point ?? gamePoints,
                lowest_point: gameLeaderboardUserUpdateHttpResponse.lowest_point ?? gamePoints,
                rank: gameLeaderboardUserUpdateHttpResponse.rank ?? 0,
                rank_change_down: gameLeaderboardUserUpdateHttpResponse.rank_change_down ?? 0,
                rank_change_up: gameLeaderboardUserUpdateHttpResponse.rank_change_up ?? 0,
                points_in_lead: gameLeaderboardUserUpdateHttpResponse.points_in_lead ?? 0,
                points_to_first: gameLeaderboardUserUpdateHttpResponse.points_to_first ?? 0,
                points_to_prizes: gameLeaderboardUserUpdateHttpResponse.points_to_prizes ?? 0,
                prize_amount: gameLeaderboardUserUpdateHttpResponse.prize_amount ?? 0,
                tenant_prize_id: gameLeaderboardUserUpdateHttpResponse.tenant_prize_id ?? null,
                tenant_prize: gameLeaderboardUserUpdateHttpResponse.tenant_prize ?? null,
                tenant_prize_template: gameLeaderboardUserUpdateHttpResponse.tenant_prize_template ?? null,
                is_participation_valid: (gameLeaderboardUserUpdateHttpResponse.is_participation_invalid) ? false : true,
                is_eliminated: (gameLeaderboardUserUpdateHttpResponse.is_eliminated) ? true : false,
                is_winner: (gameLeaderboardUserUpdateHttpResponse.is_winner) ? '1' : '0',
                lost_bets_count: gameLeaderboardUserUpdateHttpResponse.lost_bets_count ?? 0,
                user: publicPlayer ?? null,
                game_user_group: gameUserGroup
            };

            if (tenantPrizeTranslations) {
                leaderboardUser.tenant_prize.translations = tenantPrizeTranslations;
            }
            if (tenantPrizeTemplateTranslations) {
                leaderboardUser.tenant_prize_template.translations = tenantPrizeTemplateTranslations;
            }

            gameLeaderboardUserHttpResponses.push(leaderboardUser);
        });

        return gameLeaderboardUserHttpResponses;
    }

    private subscribeForLeaderboardTimestampChange(game: GameHttpResponse) {
        if (!this.leaderBoardTimestampChangeEventSubscription) {

            this.broadcastingService.joinChannel('GameDetail.' + game.game_unique_id + '.LeaderboardTimestamp');

            this.leaderBoardTimestampChangeEventSubscription =
                this.broadcastingService.listenOnEventInChannel('GameDetail.' + game.game_unique_id + '.LeaderboardTimestamp', 'Betsnaps\\Games\\GameLeaderboardTimestampChanged')
                    .subscribe((broadcastEventData: GameLeaderboardTimestampChanged) => {
                        if (broadcastEventData) {

                            this.debugService.writeMessageToConsoleLog('LEADERBOARD TIMESTAMP CHANGED');

                            this.debugService.writeMessageToConsoleLog(
                                'leaderboardTimestampBroadcast received: ' + moment().utc().format('YYYY-MM-DD HH:mm:ss.SSS')
                            );
                            this.loggerService.currentEventKey = 'broadcastLeaderboardTimestamp-processed-' + game.game_unique_id;
                            this.loggerService.startMeasurement('total');

                            this.leaderboardTimestampSubject.next(broadcastEventData.recalculationTimestamp);
                        }
                    });
        }
    }

    private reduceListToGameUserGroupCompetitors(game: GameHttpResponse, gameLeaderboardUsers: LeaderboardUserHttpResponse[], broadcastEventData: GameLeaderboardChanged) {

        const gameUserGroupCompetitors = [];

        game.game_user_group_competitors.forEach(
            (userPublic: PlayerPublicHttpResponse) => {
                const userIndex = broadcastEventData.gameLeaderboardUserAccess[userPublic.id];
                gameUserGroupCompetitors.push(gameLeaderboardUsers[userIndex]);
            }
        );

        gameUserGroupCompetitors.sort(function (competitor1: LeaderboardUserHttpResponse, competitor2: LeaderboardUserHttpResponse) {
            if (broadcastEventData.gameLeaderboardUserAccess[competitor1.user_id] > broadcastEventData.gameLeaderboardUserAccess[competitor2.user_id]) {
                return 1;
            } else {
                return -1;
            }
        });

        return gameUserGroupCompetitors;
    }

    private setH2HCompetitor(leaderBoardUsers: LeaderboardUserHttpResponse[], competitionType: number) {
        if (this.authenticationService.currentUser && leaderBoardUsers) {
            if (competitionType === 1) {
                this.h2hCompetitor = leaderBoardUsers.find(
                    (leaderBoardUser: LeaderboardUserHttpResponse) => leaderBoardUser.user.id !== this.authenticationService.currentUser.id
                );
            } else if (competitionType === 3) {
                const currentLeaderBoardUser = leaderBoardUsers.find(
                    (leaderBoardUser: LeaderboardUserHttpResponse) => leaderBoardUser.user.id === this.authenticationService.currentUser.id
                );

                if (currentLeaderBoardUser) {
                    this.h2hCompetitor = leaderBoardUsers.find(
                        (leaderBoardUser: LeaderboardUserHttpResponse) => leaderBoardUser.game_user_group.group_id === currentLeaderBoardUser.game_user_group.group_id && leaderBoardUser.user.id !== currentLeaderBoardUser.user.id
                    );
                    this.h2hCompetitor.game_user_group['is_participation_valid'] = this.h2hCompetitor['is_participation_valid'];
                    this.h2hCompetitor.game_user_group['is_eliminated'] = this.h2hCompetitor['is_eliminated'];
                }
            }
        }
    }

    public getUserBets(gameUniqueId: string, userId: number, forceReload: boolean = false) {
        if ((!this.userBetsSubject.value || !this.userBetsFetchedFromApi || forceReload) && !this.userBetsRequestRunning) {
            this.userBetsRequestRunning = true;
            this.apiGetRequestSubscriptions.push(
                this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsGet(
                    this.tenantService.tenantData.id,
                    gameUniqueId,
                    userId
                ).pipe(take(1))
                .subscribe({
                    next: (userBetList: UserBetListHttpResponse) => {
                        const userBetsMatchGroup: UserBetHttpResponse[][] = [];
                        if (userBetList.total > 0) {
                            userBetList.results.forEach(
                                (userBet: UserBetHttpResponse) => {
                                    if (!userBetsMatchGroup['match' + userBet.match_id]) {
                                        userBetsMatchGroup['match' + userBet.match_id] = [];
                                    }
                                    userBetsMatchGroup['match' + userBet.match_id].push(userBet);
                                }
                            );
                        }
                        this.userBetsSubject.next(userBetsMatchGroup);
                        this.calculateBetCount();
                        this.userBetsFetchedFromApi = true;
                        this.userBetsRequestRunning = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.userBetsRequestRunning = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                })
            );
        }
    }

    public getUserBetsForLeaderboardUser(gameUniqueId: string, userId: number): Observable<UserBetHttpResponse[][]> {
        return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsGet(
            this.tenantService.tenantData.id,
            gameUniqueId,
            userId
        ).pipe(map(
            (userBetList: UserBetListHttpResponse) => {
                const userBetsMatchGroup: UserBetHttpResponse[][] = [];
                if (userBetList.total > 0) {
                    userBetList.results.forEach(
                        (userBet: UserBetHttpResponse) => {

                            if (!userBetsMatchGroup['match' + userBet.match_id]) {
                                userBetsMatchGroup['match' + userBet.match_id] = [];
                            }
                            userBetsMatchGroup['match' + userBet.match_id].push(userBet);

                        }
                    );
                }
                return userBetsMatchGroup;
            }
        ));
    }

    public placeBet(betPoints: number, outcome: MatchMarketOutcomeHttpResponse, userId: number): Observable<UserBetHttpResponse> {

        this.raiseUserBetCount();
        const currentUserBetCount = this.game.current_user_bet_count;

        const betBody: UserBetPlaceBetRequest = {
            'match_id': outcome.match_id,
            'market_id': outcome.market_id,
            'specifier': outcome.specifier_val,
            'outcome_id': outcome.outcome_id,
            'points': betPoints,
            'odd': outcome.odd_decimal
        };
        return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsPost(
            this.tenantService.tenantData.id,
            this.game.game_unique_id,
            userId,
            betBody
        ).pipe(map(
            (userBet: UserBetHttpResponse) => {
                // add bet to userBetList
                this.addBet(userBet);
                this.raiseUserOpenBetCount();

                if (userBet.status === UserBetStateEnum.ACTIVE) {
                    this.showPlaceBetSuccessMessage(currentUserBetCount);
                }

                return userBet;
            }
        ));
    }

    public placeBetBatch(bets: WidgetBet[], userId: number): Observable<UserBetPlaceBetBatchResponse> {
        this.raiseUserBetCount(bets.length);

        const betBatch = [];
        bets.forEach((widgetBet: WidgetBet) => {
            const betBody: UserBetPlaceBetRequest = {
                'match_id': widgetBet.outcome.match_id,
                'market_id': widgetBet.outcome.market_id,
                'specifier': widgetBet.outcome.specifier_val,
                'outcome_id': widgetBet.outcome.outcome_id,
                'points': widgetBet.points,
                'odd': widgetBet.outcome.odd_decimal
            };
            betBatch.push(betBody);
        });

        const betBatchBody: UserBetPlaceBetBatchRequest = {
            'allow_failed_bets': true,
            'bets': betBatch
        };

        return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsBatchPost(
            this.tenantService.tenantData.id,
            this.game.game_unique_id,
            userId,
            betBatchBody
        ).pipe(map(
            (userBetPlaceBetBatchResponse: UserBetPlaceBetBatchResponse) => {
                // add placed bets to userBetList
                userBetPlaceBetBatchResponse.user_bets.forEach((gameUserBet: GameUserBetHttpResponse) => {
                    this.addBet(gameUserBet);
                });
                this.raiseUserOpenBetCount(userBetPlaceBetBatchResponse.user_bets.length);

                // if at least one failed show error message
                if (userBetPlaceBetBatchResponse.report.find((userBetPlaceBetReport: UserBetPlaceBetReportHttpResponse) => userBetPlaceBetReport.bet_place_status === 'error')) {
                    this.translateService.get(['GENERAL.LABELS.NOTIFICATIONS.placebetbatch_not_all_placed'])
                        .pipe(take(1)).subscribe(
                        translation => {
                            this.myNotificationsService.createNotificationToast('', translation['GENERAL.LABELS.NOTIFICATIONS.placebetbatch_not_all_placed'], NotificationType.Error);
                        });
                }

                return userBetPlaceBetBatchResponse;
            }
        ));
    }

    private showPlaceBetSuccessMessage(currentUserBetCount: number) {

        if (this.game.max_bet_count > 0) {
            const betNumber = new OrdinalNumberPipe(this.authenticationService).transform(currentUserBetCount);
            const remainingBetCount = this.game.max_bet_count - currentUserBetCount;

            this.translateService.get('GENERAL.LABELS.NOTIFICATIONS.placebet_success_withcount', {
                bet_number: betNumber,
                remaining_bet_count: remainingBetCount
            }).pipe(take(1)).subscribe(
                translation => {
                    this.myNotificationsService.createNotificationToast(
                        '',
                        translation,
                        NotificationType.Success
                    );
                });

        } else {
            this.myNotificationsService.createNotificationToast(
                '',
                this.translations.labelTranslations['NOTIFICATIONS']['placebet_success'],
                NotificationType.Success
            );
        }
    }

    private showCashOutSuccessMessage() {
        this.myNotificationsService.createNotificationToast(
            '',
            this.translations.labelTranslations['NOTIFICATIONS']['cashoutbet_success'],
            NotificationType.Success
        );
    }

    private addBet(userBet: UserBetHttpResponse) {
        let currentBetListResults = this.userBetsSubject.value;
        if (!currentBetListResults) {
            currentBetListResults = [];
        }
        if (!currentBetListResults['match' + userBet.match_id]) {
            currentBetListResults['match' + userBet.match_id] = [];
        }
        const betExistsInList = currentBetListResults['match' + userBet.match_id].find(
            (userBetInList: UserBetHttpResponse) => userBetInList.user_bet_id === userBet.user_bet_id
        );
        if (!betExistsInList) {
            currentBetListResults['match' + userBet.match_id].push(userBet);
            this.userBetsSubject.next(currentBetListResults);
        }
    }


    private updateBet(userBet: UserBetHttpResponse) {
        const currentBetListResults = this.userBetsSubject.value;
        if (currentBetListResults) {
            currentBetListResults['match' + userBet.match_id] = currentBetListResults['match' + userBet.match_id].map(
                (userBetInList: UserBetHttpResponse) => {
                    if (userBetInList.user_bet_id === userBet.user_bet_id) {
                        return userBet;
                    } else {
                        return userBetInList;
                    }
                }
            );
            this.userBetsSubject.next(currentBetListResults);
        }
    }


    private addFailedBet(userBet: UserBetHttpResponse) {
        this.failedBets.push(userBet);
    }

    public isFailedBet(userBet: UserBetHttpResponse): boolean {
        const betExists = this.failedBets.find(
            (userBetInList: UserBetHttpResponse) => userBetInList.user_bet_id === userBet.user_bet_id
        );

        if (betExists) {
            return true;
        }

        return false;
    }

    public removeFailedBet(userBet: UserBetHttpResponse) {
        this.failedBets = this.failedBets.filter(
            (userBetInList: UserBetHttpResponse) => {
                if (userBetInList.user_bet_id !== userBet.user_bet_id) {
                    return userBet;
                }
            }
        );

        this.userBetsSubject.next(this.userBetsSubject.value);
    }

    public cashOutBet(userBet: UserBetHttpResponse, cashoutPoints: number): Observable<UserBetHttpResponse> {
        const cashOutBody: UserBetCashoutRequest = {
            'cashout_points': cashoutPoints
        };
        return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersUserIdBetsUserBetIdCashoutPut(
            this.tenantService.tenantData.id,
            userBet.game_unique_id,
            userBet.user_id,
            userBet.user_bet_id,
            cashOutBody
        ).pipe(map(
            (userBet: UserBetHttpResponse) => {
                // update bet in userBetList
                this.updateBet(userBet);
                if (userBet.status === UserBetStateEnum.CASHED_OUT) {
                    // add total points of bet to gamepoints
                    this.addToUserGamePoints(userBet.total_point);
                    this.reduceUserOpenBetCount();
                    this.showCashOutSuccessMessage();
                }
                return userBet;
            }
        ));
    }


    private calculateBetCount() {
        let betsCount = 0;
        const currentBetListResults = this.userBetsSubject.value;
        if (currentBetListResults && Object.keys(currentBetListResults).length > 0) {
            for (const userBetsMatchGroupKey in currentBetListResults) {
                if (currentBetListResults[userBetsMatchGroupKey] && currentBetListResults[userBetsMatchGroupKey].length > 0) {
                    currentBetListResults[userBetsMatchGroupKey].forEach(
                        (userBet: UserBetHttpResponse) => {
                            // count only bets with status pending, cashout_pending, live, cashed out, auto cashed out and settled without rollback
                            if ((userBet.status === UserBetStateEnum.PENDING || userBet.status === UserBetStateEnum.CASHOUT_PENDING || userBet.status === UserBetStateEnum.ACTIVE || userBet.status === UserBetStateEnum.SETTLED || userBet.status === UserBetStateEnum.CASHED_OUT || userBet.status === UserBetStateEnum.AUTO_CASHED_OUT)
                                && !userBet.is_rolled_back) {
                                betsCount += 1;
                            }
                        }
                    );
                }
            }
        }

        if (this.placeBetDialogPendingBets > 0) {
            betsCount = betsCount + this.placeBetDialogPendingBets;
        }

        this.game.current_user_bet_count = betsCount;
    }

    private subscribeToGameBroadcastingEvents(game: GameHttpResponse) {

        this.broadcastingService.joinChannel('GameDetail.' + game.game_unique_id);

        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + game.game_unique_id, 'Betsnaps\\Games\\GameFullyBooked')
                .subscribe((broadcastEventData: GameStateChanged) => {
                    if (broadcastEventData) {
                        this.debugService.writeMessageToConsoleLog('GAME FULLYBOOKED - ' + game.game_unique_id);
                        this.handleGameStateChange(broadcastEventData);
                    }
                })
        );
        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + game.game_unique_id, 'Betsnaps\\Games\\GameStarted')
                .subscribe((broadcastEventData: GameStateChanged) => {
                    if (broadcastEventData && this.game) {
                        this.debugService.writeMessageToConsoleLog('GAME STARTED - ' + game.game_unique_id);
                        if (this.game.competition_type === 3) {
                            this.getGameData(this.game.game_unique_id, false, true);
                        } else {
                            this.handleGameStateChange(broadcastEventData);
                        }
                    }
                })
        );
        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + game.game_unique_id, 'Betsnaps\\Games\\GameFinished')
                .subscribe((broadcastEventData: GameStateChanged) => {
                    if (broadcastEventData && this.game) {
                        this.debugService.writeMessageToConsoleLog('GAME FINISHED - ' + game.game_unique_id);
                        this.handleGameStateChange(broadcastEventData);
                    }
                })
        );
        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + game.game_unique_id, 'Betsnaps\\Games\\GamePrizeDistributed')
                .subscribe((broadcastEventData: GameStateChanged) => {
                    if (broadcastEventData && this.game) {
                        this.debugService.writeMessageToConsoleLog('GAME PRIZE DISTRIBUTED - ' + game.game_unique_id);
                        this.handleGameStateChange(broadcastEventData);
                    }
                })
        );
        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + game.game_unique_id, 'Betsnaps\\Games\\GameClosed')
                .subscribe((broadcastEventData: GameStateChanged) => {
                    if (broadcastEventData && this.game) {
                        this.debugService.writeMessageToConsoleLog('GAME CLOSED - ' + game.game_unique_id);
                        this.handleGameStateChange(broadcastEventData);
                    }
                })
        );
        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + game.game_unique_id, 'Betsnaps\\Games\\GameCancelled')
                .subscribe((broadcastEventData: GameStateChanged) => {
                    if (broadcastEventData && this.game) {
                        this.debugService.writeMessageToConsoleLog('GAME CANCELLED - ' + game.game_unique_id);
                        this.handleGameStateChange(broadcastEventData);
                    }
                })
        );
        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + game.game_unique_id, 'Betsnaps\\Games\\UserJoinedGame')
                .subscribe((broadcastEventData: UserJoinedGameEvent) => {
                    if (broadcastEventData && this.game) {
                        this.debugService.writeMessageToConsoleLog('USER JOINED ' + broadcastEventData.gameUserHttpResponse.user_id);
                        const receivedGame = broadcastEventData.gameHttpResponse;
                        const receivedGameUser = broadcastEventData.gameUserHttpResponse;
                        const receivedPrizeStructurePrizes = broadcastEventData.calculatedPrizeHttpResponses;
                        const currentGame = this.game;
                        if (currentGame.game_unique_id === receivedGame.game_unique_id) {
                            if (this.authenticationService.currentUser && this.authenticationService.currentUser.id === receivedGameUser.user_id) {
                                // if received user is current user load game data new from api to receive game user
                                this.getGameData(currentGame.game_unique_id);
                                if (!this.joinedGameListSubject.getValue()) {
                                    this.getJoinedGameList(
                                        this.authenticationService.currentUser.id
                                    );
                                } else {
                                    const currentJoinedGameList = this.joinedGameListSubject.getValue();
                                    const gameExistsInList = currentJoinedGameList.results.find(
                                        (gameInList: GameHttpResponse) => gameInList.game_unique_id === game.game_unique_id);
                                    if (!gameExistsInList) {
                                        currentJoinedGameList.results.push(game);
                                        this.joinedGameListSubject.next(currentJoinedGameList);
                                    }
                                }
                            } else {
                                // only update users count and current prize pool
                                const newGame = currentGame;
                                newGame.users_count = receivedGame.users_count;
                                newGame.current_prize_pool = receivedGame.current_prize_pool;
                                newGame.h2h_competitors = receivedGame.h2h_competitors;

                                if (!this.gameRequestRunning) {
                                    // if user is a friend add to current_user_friends
                                    const userFriend = this.friendsService.userIsFriend(receivedGameUser.user_id);
                                    if (userFriend) {
                                        if (!newGame.current_user_friends) {
                                            newGame.current_user_friends = [];
                                        }
                                        const friendExistsInList = newGame.current_user_friends.find(
                                            (friendInList: FriendHttpResponse) => friendInList.user_id === receivedGameUser.user_id);
                                        if (!friendExistsInList) {
                                            newGame.current_user_friends.push(userFriend);
                                        }
                                    }
                                }

                                this.gameSubject.next(newGame);
                            }

                            this.gameUsersChangedSubject.next(true);

                            // update prize structure
                            if (this.prizeStructureSubject.getValue()) {
                                const currentPrizeStructure = this.prizeStructureSubject.getValue();
                                currentPrizeStructure.results = receivedPrizeStructurePrizes;
                                this.prizeStructureSubject.next(currentPrizeStructure);
                            }
                        }
                    }
                })
        );
        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + game.game_unique_id, 'Betsnaps\\Games\\UserLeftGame')
                .subscribe((broadcastEventData: UserLeftGameEvent) => {
                    if (broadcastEventData && this.game) {
                        this.debugService.writeMessageToConsoleLog('USER LEFT ' + broadcastEventData.playerPublicHttpResponse.id);
                        const receivedGame = broadcastEventData.gameHttpResponse;
                        const receivedUser = broadcastEventData.playerPublicHttpResponse;
                        const receivedPrizeStructurePrizes = broadcastEventData.calculatedPrizeHttpResponses;
                        const currentGame = this.game;
                        if (currentGame.game_unique_id === receivedGame.game_unique_id) {
                            const newGame = currentGame;
                            newGame.users_count = receivedGame.users_count;
                            newGame.current_prize_pool = receivedGame.current_prize_pool;
                            newGame.h2h_competitors = receivedGame.h2h_competitors;
                            if (this.authenticationService.currentUser && this.authenticationService.currentUser.id === receivedUser.id) {
                                newGame.is_current_user_joined = false;
                                newGame.game_user = null;
                                if (this.joinedGameListSubject.value) {
                                    if (this.joinedGameListSubject.getValue()) {
                                        const currentJoinedGameList = this.joinedGameListSubject.getValue();
                                        currentJoinedGameList.results = currentJoinedGameList.results.filter(
                                            (gameInList: GameHttpResponse) => gameInList.game_unique_id !== game.game_unique_id);
                                        this.joinedGameListSubject.next(currentJoinedGameList);
                                    }
                                }
                                this.userBetsSubject.next(null);
                                this.game.current_user_bet_count = 0;
                            } else {
                                // if user is a friend remove from current_user_friends
                                const userFriend = this.friendsService.userIsFriend(receivedUser.id);
                                if (userFriend) {
                                    newGame.current_user_friends = newGame.current_user_friends.filter(
                                        (friendInList: FriendHttpResponse) => friendInList.friend_user_id !== userFriend.friend_user_id
                                    );
                                }
                            }
                            this.gameSubject.next(newGame);

                            this.gameUsersChangedSubject.next(true);

                            // update prize structure
                            if (this.prizeStructureSubject.getValue()) {
                                const currentPrizeStructure = this.prizeStructureSubject.getValue();
                                currentPrizeStructure.results = receivedPrizeStructurePrizes;
                                this.prizeStructureSubject.next(currentPrizeStructure);
                            }
                        }
                    }
                })
        );
        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + game.game_unique_id, 'Betsnaps\\Games\\GameMatchCancelled')
                .subscribe((broadcastEventData: GameMatchCancelledEvent) => {
                    if (broadcastEventData && this.game) {
                        const receivedGameMatch = broadcastEventData.gameMatchHttpResponse;
                        this.debugService.writeMessageToConsoleLog('GameMatchCancelled ' + receivedGameMatch.game_match_id);
                        // update game match
                        const currentMatches = this.gameMatchesSubject.getValue();
                        const updatedMatches = currentMatches.map(
                            (gameMatchInList: GameMatchHttpResponse) => {
                                if (gameMatchInList.game_match_id === receivedGameMatch.game_match_id) {
                                    return receivedGameMatch;
                                } else {
                                    return gameMatchInList;
                                }
                            }
                        );
                        this.gameMatchesSubject.next(updatedMatches);
                        // unsubscribe from events
                        if (this.matchBroadcastEventSubscriptions[receivedGameMatch.game_match_id] &&
                            this.matchBroadcastEventSubscriptions[receivedGameMatch.game_match_id].subscriptions.length > 0) {
                            this.matchBroadcastEventSubscriptions[receivedGameMatch.game_match_id].subscriptions.forEach(subscription => subscription.unsubscribe());
                            this.broadcastingService.leaveChannel('Match.' + this.matchBroadcastEventSubscriptions[receivedGameMatch.game_match_id].matchId);
                        }
                        // update user bets if active bets exist
                        if (this.authenticationService.currentUser) {
                            const currentBetListResults = this.userBetsSubject.getValue();
                            if (currentBetListResults && currentBetListResults['match' + receivedGameMatch.match_id]) {
                                const activeBetsOnMatch = currentBetListResults['match' + receivedGameMatch.match_id].filter(
                                    (userBet: UserBetHttpResponse) => (userBet.status === UserBetStateEnum.PENDING || userBet.status === UserBetStateEnum.CASHOUT_PENDING || userBet.status === UserBetStateEnum.ACTIVE));
                                if (activeBetsOnMatch && activeBetsOnMatch.length > 0) {
                                    this.getUserBets(
                                        game.game_unique_id,
                                        this.authenticationService.currentUser.id,
                                        true
                                    );
                                }
                            }
                        }
                    }
                })
        );

        // only needed if logged in
        if (this.authenticationService.currentUser && game.is_current_user_joined) {
            if (game.sport_id === 999) {
                // only for casino games
                this.subscribeToGameVendorGameParticipationsBroadcastingEvents();
            } else {
                // only for games with matches and bets
                this.subscribeToUserBetEvents(game);
            }
        }

    }

    private subscribeToUserBetEvents(game: GameHttpResponse) {
        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + game.game_unique_id, 'Betsnaps\\Games\\AutoCashedOutAllActiveBets')
                .subscribe((broadcastEventData: AutoCashedOutAllActiveBetsEvent) => {
                    if (broadcastEventData && this.game) {
                        this.debugService.writeMessageToConsoleLog('AutoCashedOutAllBets ' + game.game_unique_id);
                        if (this.authenticationService.currentUser) {
                            const activeBetCount = this.reloadUserBetsIfActiveBetsExist(game);
                            if (activeBetCount > 0) {
                                this.myNotificationsService.createNotificationToast(
                                    '',
                                    this.translations.labelTranslations['NOTIFICATIONS']['auto_cashed_out_bets'],
                                    NotificationType.Success
                                );
                            }
                        }
                    }
                })
        );

        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + game.game_unique_id, 'Betsnaps\\Games\\AutoCanceledAllActiveBets')
                .subscribe((broadcastEventData: AutoCanceledAllActiveBets) => {
                    if (broadcastEventData && this.game) {
                        this.debugService.writeMessageToConsoleLog('AutoCanceledAllActiveBets ' + game.game_unique_id);
                        if (this.authenticationService.currentUser) {
                            const activeBetCount = this.reloadUserBetsIfActiveBetsExist(game);
                            if (activeBetCount > 0) {
                                this.myNotificationsService.createNotificationToast(
                                    '',
                                    this.translations.labelTranslations['NOTIFICATIONS']['auto_canceled_bets'],
                                    NotificationType.Success
                                );
                            }
                        }
                    }
                })
        );

        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + game.game_unique_id, 'Betsnaps\\Games\\AutoFailedAllPendingBets')
                .subscribe((broadcastEventData: AutoFailedAllPendingBetsEvent) => {
                    if (broadcastEventData && this.game) {
                        this.debugService.writeMessageToConsoleLog('AutoFailedAllPendingBets ' + game.game_unique_id);
                        if (this.authenticationService.currentUser) {
                            const currentBetListResults = this.userBetsSubject.getValue();
                            if (currentBetListResults && Object.keys(currentBetListResults).length > 0) {
                                let pendingBetsToAutoFailCount = 0;
                                for (const userBetsMatchGroupKey in currentBetListResults) {
                                    if (currentBetListResults[userBetsMatchGroupKey]) {
                                        const pendingBetsToAutoFailOnMatch = currentBetListResults[userBetsMatchGroupKey].filter(
                                            (userBet: UserBetHttpResponse) => (userBet.status === UserBetStateEnum.PENDING && ((!broadcastEventData.matchId || broadcastEventData.matchId === userBet.match_id) && (!broadcastEventData.dateLimit || (userBet.pending_at && broadcastEventData.dateLimit && moment(broadcastEventData.dateLimit).isSameOrAfter(moment(userBet.pending_at)))))));
                                        pendingBetsToAutoFailCount += pendingBetsToAutoFailOnMatch.length;
                                        pendingBetsToAutoFailOnMatch.forEach((
                                            (userBet: UserBetHttpResponse) => {
                                                this.addFailedBet(userBet);
                                            }));
                                    }
                                }

                                if (pendingBetsToAutoFailCount > 0) {
                                    this.getGameData(game.game_unique_id);
                                    this.getUserBets(
                                        game.game_unique_id,
                                        this.authenticationService.currentUser.id,
                                        true
                                    );

                                    this.myNotificationsService.createNotificationToast(
                                        '',
                                        this.translations.labelTranslations['NOTIFICATIONS']['auto_failed_all_pending_bets'],
                                        NotificationType.Error
                                    );
                                }
                            }
                        }
                    }
                })
        );


        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('GameDetail.' + game.game_unique_id, 'Betsnaps\\Games\\AutoFailedAllPendingCashouts')
                .subscribe((broadcastEventData: AutoFailedAllPendingCashoutsEvent) => {
                    if (broadcastEventData && this.game) {
                        this.debugService.writeMessageToConsoleLog('AutoFailedAllPendingCashouts ' + game.game_unique_id);
                        if (this.authenticationService.currentUser) {
                            const currentBetListResults = this.userBetsSubject.getValue();
                            if (currentBetListResults && Object.keys(currentBetListResults).length > 0) {
                                let pendingCashoutsToAutoFailCount = 0;
                                for (const userBetsMatchGroupKey in currentBetListResults) {
                                    if (currentBetListResults[userBetsMatchGroupKey]) {
                                        const pendingCashoutsToAutoFailOnMatch = currentBetListResults[userBetsMatchGroupKey].filter(
                                            (userBet: UserBetHttpResponse) => (userBet.status === UserBetStateEnum.CASHOUT_PENDING && (!broadcastEventData.matchId || (broadcastEventData.matchId === userBet.match_id)) && (!broadcastEventData.dateLimit || (userBet.cashout_pending_at && broadcastEventData.dateLimit && moment(broadcastEventData.dateLimit).isSameOrAfter(moment(userBet.cashout_pending_at))))));
                                        pendingCashoutsToAutoFailCount += pendingCashoutsToAutoFailOnMatch.length;
                                    }
                                }
                                if (pendingCashoutsToAutoFailCount > 0) {
                                    this.getUserBets(
                                        game.game_unique_id,
                                        this.authenticationService.currentUser.id,
                                        true
                                    );

                                    this.myNotificationsService.createNotificationToast(
                                        '',
                                        this.translations.labelTranslations['NOTIFICATIONS']['auto_failed_all_pending_cashouts'],
                                        NotificationType.Error
                                    );
                                }
                            }
                        }
                    }
                })
        );

        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this.authenticationService.currentUser.id, 'Betsnaps\\Games\\UserPlacedBet')
                .subscribe((broadcastEventData: UserPlacedBetEvent) => {
                    if (broadcastEventData && this.game) {
                        this.debugService.writeMessageToConsoleLog('USER PLACED BET ' + broadcastEventData.userBetHttpResponse.user_id);
                        if (this.authenticationService.currentUser.id === broadcastEventData.userBetHttpResponse.user_id) {

                            this.game.current_user_bet_count += 1;
                            this.substractFromUserGamePoints(broadcastEventData.userBetHttpResponse.point);

                            // add bet to match bets
                            this.addBet(broadcastEventData.userBetHttpResponse);
                        }
                    }
                })
        );

        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this.authenticationService.currentUser.id, 'Betsnaps\\Games\\BetConfirmed')
                .subscribe((broadcastEventData: BetConfirmedEvent) => {
                    if (broadcastEventData && this.game) {

                        this.debugService.writeMessageToConsoleLog('BET CONFIRMED');
                        this.debugService.writeMessageToConsoleLog(broadcastEventData.userBetHttpResponse);

                        if (this.authenticationService.currentUser.id === broadcastEventData.userBetHttpResponse.user_id) {

                            const pendingBetCount = this.pendingBetsCount() - 1;
                            let currentUserBetCount = this.game.current_user_bet_count;
                            if (pendingBetCount > 0) {
                                currentUserBetCount -= pendingBetCount;
                            }

                            this.updateBet(broadcastEventData.userBetHttpResponse);
                            this.showPlaceBetSuccessMessage(currentUserBetCount);
                        }
                    }
                })
        );

        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this.authenticationService.currentUser.id, 'Betsnaps\\Games\\BetFailed')
                .subscribe((broadcastEventData: BetFailedEvent) => {
                    if (broadcastEventData && this.game) {

                        this.debugService.writeMessageToConsoleLog('BET FAILED');
                        this.debugService.writeMessageToConsoleLog(broadcastEventData.userBetHttpResponse);

                        if (this.authenticationService.currentUser.id === broadcastEventData.userBetHttpResponse.user_id) {

                            this.addFailedBet(broadcastEventData.userBetHttpResponse);
                            this.updateBet(broadcastEventData.userBetHttpResponse);
                            this.reduceUserBetCount();
                            this.addToUserGamePoints(broadcastEventData.userBetHttpResponse.point);

                            if (broadcastEventData.errorCode && broadcastEventData.errorCode === 'odd_bet_value_not_allowed_difference') {
                                this.getMatchMarkets(this.game.game_unique_id, broadcastEventData.userBetHttpResponse.match_id, true);
                            }

                            if (broadcastEventData.errorCode) {
                                this.debugService.writeMessageToConsoleLog('Error Code ' + broadcastEventData.errorCode);
                                this.errorService.handleGeneralError(broadcastEventData.errorCode);
                            }
                        }
                    }
                })
        );

        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this.authenticationService.currentUser.id, 'Betsnaps\\Games\\UserCashedOutBet')
                .subscribe((broadcastEventData: UserCashedOutBetEvent) => {
                    if (broadcastEventData && this.game) {
                        this.debugService.writeMessageToConsoleLog('USER CASHED OUT BET ' + broadcastEventData.userBetHttpResponse.user_id);
                        if (this.authenticationService.currentUser.id === broadcastEventData.userBetHttpResponse.user_id) {
                            // update bet in userBetList
                            this.updateBet(broadcastEventData.userBetHttpResponse);
                            // add total points of bet to gamepoints
                            if (broadcastEventData.userBetHttpResponse.status === UserBetStateEnum.CASHED_OUT) {
                                this.addToUserGamePoints(broadcastEventData.userBetHttpResponse.total_point);
                            }
                        }
                    }
                })
        );


        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this.authenticationService.currentUser.id, 'Betsnaps\\Games\\CashoutConfirmed')
                .subscribe((broadcastEventData: CashoutConfirmedEvent) => {
                    if (broadcastEventData && this.game) {

                        this.debugService.writeMessageToConsoleLog('CASH OUT CONFIRMED');
                        this.debugService.writeMessageToConsoleLog(broadcastEventData.userBetHttpResponse);

                        if (this.authenticationService.currentUser.id === broadcastEventData.userBetHttpResponse.user_id) {

                            this.updateBet(broadcastEventData.userBetHttpResponse);
                            this.addToUserGamePoints(broadcastEventData.userBetHttpResponse.total_point);
                            this.showCashOutSuccessMessage();
                        }
                    }
                })
        );

        this.gameBroadcastEventSubscriptions.push(
            this.broadcastingService.listenOnEventInChannel('User.' + this.authenticationService.currentUser.id, 'Betsnaps\\Games\\CashoutFailed')
                .subscribe((broadcastEventData: CashoutFailedEvent) => {
                    if (broadcastEventData && this.game) {

                        this.debugService.writeMessageToConsoleLog('CASH OUT FAILED');
                        this.debugService.writeMessageToConsoleLog(broadcastEventData.userBetHttpResponse);

                        if (this.authenticationService.currentUser.id === broadcastEventData.userBetHttpResponse.user_id) {

                            this.updateBet(broadcastEventData.userBetHttpResponse);

                            if (broadcastEventData.errorCode) {
                                this.debugService.writeMessageToConsoleLog('Error Code ' + broadcastEventData.errorCode);
                                this.errorService.handleGeneralError(broadcastEventData.errorCode);
                            }
                        }
                    }
                })
        );
    }

    private reloadUserBetsIfActiveBetsExist(game: GameHttpResponse): number {
        let activeBetCount = 0;
        const currentBetListResults = this.userBetsSubject.getValue();
        if (currentBetListResults && Object.keys(currentBetListResults).length > 0) {
            for (const userBetsMatchGroupKey in currentBetListResults) {
                if (currentBetListResults[userBetsMatchGroupKey]) {
                    const activeBetsOnMatch = currentBetListResults[userBetsMatchGroupKey].filter(
                        (userBet: UserBetHttpResponse) => (userBet.status === UserBetStateEnum.CASHOUT_PENDING || userBet.status === UserBetStateEnum.ACTIVE));
                    activeBetCount += activeBetsOnMatch.length;
                }
            }
            if (activeBetCount > 0) {
                this.getUserBets(
                    game.game_unique_id,
                    this.authenticationService.currentUser.id,
                    true
                );
            }
        }
        return activeBetCount;
    }

    private pendingBetsCount(): number {
        let betsCount = 0;
        const currentBetListResults = this.userBetsSubject.value;
        if (currentBetListResults && Object.keys(currentBetListResults).length > 0) {
            for (const userBetsMatchGroupKey in currentBetListResults) {
                if (currentBetListResults[userBetsMatchGroupKey] && currentBetListResults[userBetsMatchGroupKey].length > 0) {
                    currentBetListResults[userBetsMatchGroupKey].forEach(
                        (userBet: UserBetHttpResponse) => {
                            if (userBet.status === UserBetStateEnum.PENDING) {
                                betsCount += 1;
                            }
                        }
                    );
                }
            }
        }

        return betsCount;
    }

    private handleGameStateChange(broadcastEventData: GameStateChanged, receivedGameData: GameHttpResponse = null) {
        let receivedGame;
        if (receivedGameData) {
            receivedGame = receivedGameData;
        } else {
            receivedGame = broadcastEventData.gameHttpResponse;
        }
        const currentGame = this.game;
        if (currentGame && currentGame.game_unique_id === receivedGame.game_unique_id) {
            const newGame = currentGame;
            newGame.game_state = receivedGame.game_state;
            newGame.real_end_date = receivedGame.real_end_date;
            newGame.users_count = receivedGame.users_count;
            newGame.current_prize_pool = receivedGame.current_prize_pool;
            newGame.updated_at = receivedGame.updated_at;
            this.gameSubject.next(newGame);
            if (newGame.game_state === 3) {
                this.subscribeForLeaderboardEvents(newGame);
                this.getFullLeaderBoard(newGame);
            }
            // if game state is closed or cancelled unsubscribe from broadcasting events
            if (newGame.game_state > 6) {
                this.abortRequestsAndUnsubscribeFromBroadcastEvents();
                if (currentGame.game_state === 99) {
                    this.subscribeToMatchBroadcastingEvents();
                }
            }
        }
    }

    private handleOddsData(matchId: number, matchMarketHttpResponses: MatchMarketHttpResponse[]) {
        // update outcomes in user bets
        if (matchMarketHttpResponses) {
            const markets = matchMarketHttpResponses;
            const currentBetListResults = this.userBetsSubject.getValue();
            if (currentBetListResults && currentBetListResults['match' + matchId]) {
                currentBetListResults['match' + matchId] = currentBetListResults['match' + matchId].map(
                    (userBet: UserBetHttpResponse) => {
                        const newMarketData = markets.find(
                            (matchMarket: MatchMarketHttpResponse) =>
                                matchMarket.market_id === userBet.market_id &&
                                matchMarket.specifier_val === userBet.specifier_val
                        );
                        if (newMarketData) {
                            //MatchMarketUpdateHttpResponse of inactive Market has no name data
                            if (!newMarketData.market_name) {
                                newMarketData.market_name = userBet.market.market_name;
                                newMarketData.translations = userBet.market.translations;
                            }
                            userBet.market = newMarketData;
                            const newOutcomeData = newMarketData.outcomes.find(
                                (outcome: MatchMarketOutcomeHttpResponse) => outcome.outcome_id === userBet.outcome_id
                            );
                            if (newOutcomeData) {
                                //MatchMarketOutcomeUpdateHttpResponse of inactive Market has no name data
                                if (!newOutcomeData.outcome_name) {
                                    newOutcomeData.outcome_name = userBet.outcome.outcome_name;
                                    newOutcomeData.translations = userBet.outcome.translations;
                                }
                                userBet.outcome = newOutcomeData;
                            }
                        }
                        return userBet;
                    }
                );
                this.userBetsSubject.next(currentBetListResults);
                this.calculateBetCount();
            }
        }
    }

    private handleOddChange(broadcastEventData: OddsChangeReceived) {
        const currentGame = this.game;

        const currentMatches = this.gameMatchesSubject.getValue();
        const matchToUpdate = currentMatches.find(
            (gameMatch: GameMatchHttpResponse) => gameMatch.match_id === broadcastEventData.matchId
        );

        if (!matchToUpdate) {
            return;
        }

        //Build MatchMarketHttpResponses and MatchMarketOutcomeHttpResponses out of UpdateHttpResponses
        const originalMatchMarkets: Array<MatchMarketHttpResponse> = [];
        broadcastEventData.matchMarketUpdateHttpResponses.forEach((matchMarketUpdate: MatchMarketUpdateHttpResponse) => {
            let matchMarketTranslations = null;
            if (matchMarketUpdate.market_name_translations) {
                matchMarketTranslations = this.buildTranslationHttpResponsesFromMinimizedTranslationsString(matchMarketUpdate.market_name_translations);
                delete matchMarketUpdate.market_name_translations;
            }

            //Fill not provided values
            const matchMarket: MatchMarketHttpResponse = {
                ...matchMarketUpdate,
                match_id: broadcastEventData.matchId
            };

            if (matchMarketTranslations) {
                matchMarket.translations = matchMarketTranslations;
            }

            const outcomes: Array<MatchMarketOutcomeHttpResponse> = [];
            if (matchMarketUpdate.outcomes) {
                matchMarketUpdate.outcomes.forEach((matchMarketOutcomeUpdate: MatchMarketOutcomeUpdateHttpResponse) => {

                    let matchMarketOutcomeTranslations = null;
                    if (matchMarketOutcomeUpdate.outcome_name_translations) {
                        matchMarketOutcomeTranslations = this.buildTranslationHttpResponsesFromMinimizedTranslationsString(matchMarketOutcomeUpdate.outcome_name_translations);
                        delete matchMarketOutcomeUpdate.outcome_name_translations;
                    }

                    //Fill not provided outcome values
                    const matchMarketOutcome: MatchMarketOutcomeHttpResponse = {
                        ...matchMarketOutcomeUpdate,
                        match_id: broadcastEventData.matchId,
                        market_id: matchMarketUpdate.market_id,
                        specifier_val: matchMarketUpdate.specifier_val
                    };

                    //Fill odd_decimal_unlimited if it is not present because its identical to odd_decimal
                    if (!matchMarketOutcome.odd_decimal_unlimited) {
                        matchMarketOutcome.odd_decimal_unlimited = matchMarketOutcome.odd_decimal;
                    }

                    if (matchMarketOutcomeTranslations) {
                        matchMarketOutcome.translations = matchMarketOutcomeTranslations;
                    }
                    outcomes.push(matchMarketOutcome);
                });
            }

            matchMarket.outcomes = outcomes;
            originalMatchMarkets.push(matchMarket);
        });

        // filter markets based on tenant settings
        const filteredTenantMatchMarkets = originalMatchMarkets.filter(
            (matchMarket: MatchMarketHttpResponse) =>
                this._gameSettings.tenant_market_ids.includes(matchMarket.market_id) &&
                !this._gameSettings.tenant_market_specifier_disabled.includes(matchMarket.market_id + '_' + matchMarket.specifier_val) &&
                !this._gameSettings.tenant_match_market_disabled.includes(broadcastEventData.matchId + '_' + matchMarket.market_id) &&
                (!this.game.is_simple_snap || (this.game.is_simple_snap && (matchMarket.specifier_val === '0' || (matchMarket.specifier_val !== '0' && matchMarket.initial_favourite === true))))
        );

        // update match
        if (broadcastEventData.matchUpdateHttpResponse) {
            let currentMatchData: MatchHttpResponse = cloneDeep(matchToUpdate.match);
            currentMatchData.status = broadcastEventData.matchUpdateHttpResponse.status;
            currentMatchData.markets_count = broadcastEventData.matchUpdateHttpResponse.markets_count;
            currentMatchData.score = null;
            currentMatchData.home_statistics = null;
            currentMatchData.away_statistics = null;
            if (broadcastEventData.matchUpdateHttpResponse.score) {
                currentMatchData.score = broadcastEventData.matchUpdateHttpResponse.score;
            }
            if (broadcastEventData.matchUpdateHttpResponse.home_statistics) {
                currentMatchData.home_statistics = broadcastEventData.matchUpdateHttpResponse.home_statistics;
            }
            if (broadcastEventData.matchUpdateHttpResponse.away_statistics) {
                currentMatchData.away_statistics = broadcastEventData.matchUpdateHttpResponse.away_statistics;
            }

            if (currentGame.game_state === 99) {
                // no markets if game state is cancelled
                currentMatchData.markets_count = 0;
                currentMatchData.default_market = null;
                this.updateMatch(currentMatchData);
            } else {
                this.updateMatch(currentMatchData, filteredTenantMatchMarkets);
            }
        }

        if (currentGame.game_state !== 99) {
            // update match markets
            if (filteredTenantMatchMarkets) {
                const currentMatchMarkets = this.matchesMarketsSubject.getValue();
                if (currentMatchMarkets && currentMatchMarkets[broadcastEventData.matchId]) {
                    currentMatchMarkets[broadcastEventData.matchId] = filteredTenantMatchMarkets;
                    this.matchesMarketsSubject.next(currentMatchMarkets);
                }
            }

            // update user bets if active bets exist
            if (originalMatchMarkets) {
                const currentBetListResults = this.userBetsSubject.getValue();
                if (currentBetListResults && currentBetListResults['match' + broadcastEventData.matchId]) {
                    const activeBetsOnMatch = currentBetListResults['match' + broadcastEventData.matchId].filter(
                        (userBet: UserBetHttpResponse) => (userBet.status === UserBetStateEnum.ACTIVE || userBet.status === UserBetStateEnum.PENDING || userBet.status === UserBetStateEnum.CASHOUT_PENDING));
                    if (activeBetsOnMatch && activeBetsOnMatch.length > 0) {
                        this.handleOddsData(broadcastEventData.matchId, originalMatchMarkets);
                    }
                }
            }
        }
    }

    private buildTranslationHttpResponsesFromMinimizedTranslationsString(translationsString: string, labelField: string = 'name'): Array<TranslationHttpResponse> {
        const translations: Array<TranslationHttpResponse> = [];
        translationsString.split('||').forEach((singleTranslation: string) => {
            const singleTranslationData = singleTranslation.split('::');
            translations.push({
                id: null,
                iso_code2: singleTranslationData[0],
                label_field: labelField,
                label_value: singleTranslationData[1] ?? ''
            });
        });

        return translations;
    }

    private handleBetStop(matchId: number) {
        if (this.game && this.gameMatchesSubject.getValue()) {
            const currentGame = this.game;
            const currentMatches = this.gameMatchesSubject.getValue();
            const matchToUpdate = currentMatches.find(
                (gameMatch: GameMatchHttpResponse) => gameMatch.match_id === matchId
            );
            if (matchToUpdate && currentGame.game_state !== 99) {
                // set default market and outcomes to suspended
                if (matchToUpdate.match.default_market) {
                    if (matchToUpdate.match.default_market.market_status === 1) {
                        matchToUpdate.match.default_market.market_status = -1;
                    }
                    this.gameMatchesSubject.next(currentMatches);
                }
                // set market_status in user bets to suspended
                const currentBetListResults = this.userBetsSubject.getValue();
                if (currentBetListResults && currentBetListResults['match' + matchId]) {
                    currentBetListResults['match' + matchId] = currentBetListResults['match' + matchId].map(
                        (userBet: UserBetHttpResponse) => {
                            if (userBet.market.market_status === 1) {
                                userBet.market.market_status = -1;
                            }
                            return userBet;
                        }
                    );
                }
                this.userBetsSubject.next(currentBetListResults);
                this.calculateBetCount();
                // set all markets to suspended
                const currentMatchMarkets = this.matchesMarketsSubject.getValue();
                if (currentMatchMarkets && currentMatchMarkets[matchId]) {
                    currentMatchMarkets[matchId] = currentMatchMarkets[matchId].map(
                        (matchMarket: MatchMarketHttpResponse) => {
                            if (matchMarket.market_status === 1) {
                                matchMarket.market_status = -1;
                            }
                            return matchMarket;
                        }
                    );
                    this.matchesMarketsSubject.next(currentMatchMarkets);
                }
            }
        }
    }

    private addReceivedMarkets(broadcastEventData, newUserBetMarketStatus: number) {
        if (this.game) {
            const currentGame = this.game;
            if (currentGame.game_state !== 99) {
                const markets = broadcastEventData.matchMarketHttpResponses;

                if (markets.length > 0) {

                    const currentUser = this.authenticationService.currentUser;
                    const currentBetListResults = this.userBetsSubject.getValue();

                    const marketIdentifierList = this.getMarketIdentifierList(markets);

                    if (currentGame && currentUser && currentBetListResults && currentBetListResults['match' + broadcastEventData.matchId]) {
                        currentBetListResults['match' + broadcastEventData.matchId].forEach(
                            (userBet: UserBetHttpResponse) => {
                                if (marketIdentifierList.includes(userBet.market_id + '_' + userBet.specifier_val)) {
                                    userBet.market.market_status = newUserBetMarketStatus;
                                }
                            }
                        );
                    }

                    const currentMatchMarkets = this.matchesMarketsSubject.getValue();
                    if (currentMatchMarkets && currentMatchMarkets[broadcastEventData.matchId]) {
                        // add markets to match
                        currentMatchMarkets[broadcastEventData.matchId].push(...markets);
                        this.matchesMarketsSubject.next(currentMatchMarkets);
                    }
                }


            }
        }
    }

    private removeReceivedMarkets(broadcastEventData, newUserBetMarketStatus: number) {
        if (this.game) {
            const currentGame = this.game;
            if (currentGame.game_state !== 99) {
                const markets = broadcastEventData.matchMarketHttpResponses;

                if (markets.length > 0) {
                    const currentUser = this.authenticationService.currentUser;
                    const currentBetListResults = this.userBetsSubject.getValue();

                    const marketIdentifierList = this.getMarketIdentifierList(markets);

                    if (currentGame && currentUser && currentBetListResults && currentBetListResults['match' + broadcastEventData.matchId]) {
                        currentBetListResults['match' + broadcastEventData.matchId].forEach(
                            (userBet: UserBetHttpResponse) => {
                                if (marketIdentifierList.includes(userBet.market_id + '_' + userBet.specifier_val)) {
                                    userBet.market.market_status = newUserBetMarketStatus;
                                }
                            }
                        );
                    }

                    const currentMatchMarkets = this.matchesMarketsSubject.getValue();
                    if (currentMatchMarkets && currentMatchMarkets[broadcastEventData.matchId]) {
                        currentMatchMarkets[broadcastEventData.matchId] = currentMatchMarkets[broadcastEventData.matchId].filter(
                            (matchMarket: MatchMarketHttpResponse) =>
                                !marketIdentifierList.includes(matchMarket.market_id + '_' + matchMarket.specifier_val)
                        );
                        this.matchesMarketsSubject.next(currentMatchMarkets);
                    }
                }
            }
        }
    }

    private reloadUserBetsAndGameDataIfNecessary(broadcastEventData, userBetStates: number[]) {
        if (this.game) {
            const currentGame = this.game;
            if (currentGame.game_state !== 99) {
                const currentBetListResults = this.userBetsSubject.getValue();
                const markets = broadcastEventData.matchMarketHttpResponses;
                if (markets.length > 0) {
                    const marketIdentifierList = this.getMarketIdentifierList(markets);
                    if (currentGame && this.authenticationService.currentUser && currentBetListResults && currentBetListResults['match' + broadcastEventData.matchId]) {
                        const betsOnMatch = currentBetListResults['match' + broadcastEventData.matchId].filter(function (userBet: UserBetHttpResponse) {
                            return userBetStates.includes(userBet.status) && marketIdentifierList.includes(userBet.market_id + '_' + userBet.specifier_val);
                        });

                        if (betsOnMatch && betsOnMatch.length > 0) {
                            this.getGameData(currentGame.game_unique_id);
                            this.getUserBets(
                                currentGame.game_unique_id,
                                this.authenticationService.currentUser.id,
                                true
                            );
                        }
                    }
                }
            }
        }
    }

    private getMarketIdentifierList(markets: MatchMarketHttpResponse[]): string[] {
        const marketIdentifierList = [];
        markets.forEach(
            (matchMarket: MatchMarketHttpResponse) => {
                marketIdentifierList.push(matchMarket.market_id + '_' + matchMarket.specifier_val);
            }
        );
        return marketIdentifierList;
    }

    private updateMatch(match: MatchHttpResponse, matchMarkets: MatchMarketHttpResponse[] = null) {
        if (match && this.gameMatchesSubject.getValue()) {
            const currentMatches = this.gameMatchesSubject.getValue();
            const matchToUpdate = currentMatches.find(
                (gameMatch: GameMatchHttpResponse) => gameMatch.match_id === match.match_id
            );
            if (matchToUpdate) {
                let markets = cloneDeep(matchMarkets);
                if (markets) {
                    markets = this.removeInactiveMarketsAndOutcomes(markets);
                    match.markets_count = markets.length;
                } else {
                    match.markets_count = matchToUpdate.match.markets_count;
                }

                if (markets) {
                    match.default_market = this.setMatchDefaultMarket(match.match_id, markets);
                }

                // remove default market if market status 0
                if (match.default_market) {
                    match.markets_count -= 1;
                }

                matchToUpdate.match = match;
                this.gameMatchesSubject.next(currentMatches);
            }
        }
    }

    public removeInactiveMarketsAndOutcomes(matchMarkets): MatchMarketHttpResponse[] {
        matchMarkets = matchMarkets.filter(
            (matchMarket: MatchMarketHttpResponse) => {
                // remove settled/deactive outcomes
                if (matchMarket.outcomes) {
                    matchMarket.outcomes = this.filterMatchMarketOutcomes(matchMarket.outcomes);
                }
                return ((matchMarket.market_status === 1 || matchMarket.market_status === -1) && matchMarket.outcomes && matchMarket.outcomes.length > 0);
            }
        );
        return matchMarkets;
    }

    private filterMatchMarketOutcomes(outcomes: MatchMarketOutcomeHttpResponse[]): MatchMarketOutcomeHttpResponse[] {
        const outcomesFiltered = outcomes.filter(
            (outcome: MatchMarketOutcomeHttpResponse) => {
                return (outcome.visibility === true);
            }
        );
        return outcomesFiltered;
    }

    public setMatchDefaultMarket(matchId: number, matchMarkets: MatchMarketHttpResponse[]): MatchMarketHttpResponse {

        let defaultMarket = null;
        const defaultMarketsPool = [];

        if (matchMarkets) {
            matchMarkets.forEach(
                (matchMarket: MatchMarketHttpResponse) => {
                    if (this._gameSettings.default_market_ids.includes(matchMarket.market_id) &&
                        this._gameSettings.tenant_market_ids.includes(matchMarket.market_id) &&
                        !this._gameSettings.tenant_market_specifier_disabled.includes(matchMarket.market_id + '_' + matchMarket.specifier_val) &&
                        !this._gameSettings.tenant_match_market_disabled.includes(matchId + '_' + matchMarket.market_id) &&
                        (matchMarket.specifier_val === '0' || (matchMarket.specifier_val !== '0' && matchMarket.favourite))) {
                        defaultMarketsPool.push(matchMarket);
                    }
                }
            );
        }

        let defaultIsSet = false;
        if (this._gameSettings.default_market_ids) {
            this._gameSettings.default_market_ids.forEach(
                (defaultMarketId: number) => {
                    if (!defaultIsSet) {
                        defaultMarketsPool.forEach(
                            (matchMarket: MatchMarketHttpResponse) => {
                                if (defaultMarketId === matchMarket.market_id) {
                                    if (matchMarket.outcomes) {
                                        matchMarket.outcomes = this.filterMatchMarketOutcomes(matchMarket.outcomes);
                                        if ((matchMarket.market_status === 1 || matchMarket.market_status === -1) &&
                                            matchMarket.outcomes &&
                                            matchMarket.outcomes.length > 0) {
                                            defaultMarket = matchMarket;
                                            defaultIsSet = true;
                                        }
                                    }
                                }
                            }
                        );
                    }
                }
            );
        }

        return defaultMarket;
    }

    public refreshGameData(getGameData: boolean = true, getGameMatches: boolean = true, getGameVendorGames: boolean = true, getPrizeStructure: boolean = true, getCurrentUserBets: boolean = true, getGameVendorGameParticipations: boolean = true, loadGameSettings: boolean = true, loadGameShareData: boolean = true, loadSentGameInvitations: boolean = true, getGameLeaderboard: boolean = null) {
        if (this.game && this.game.game_unique_id && this.game.game_state < 7) {
            if (getGameData) {
                this.getGameData(this.game.game_unique_id, false, false, loadGameSettings, loadGameShareData, loadSentGameInvitations);
            }
            if (getGameMatches && this.game.sport_id !== 999) {
                this.getGameMatches(this.game.game_unique_id, true);
            }
            if (getGameVendorGames && this.game.sport_id === 999) {
                this.getGameVendorGames(this.game.game_unique_id, true);
            }
            if (getPrizeStructure) {
                if (!this.prizeStructureSubject.getValue() || (this.prizeStructureSubject.getValue() && this.game.game_state < 3)) {
                    this.getPrizeStructure(this.game, true);
                }
            }
            if (this.authenticationService.currentUser) {
                if (getGameLeaderboard === null) {
                    getGameLeaderboard = (
                        this.game.game_state > 2 &&
                        (this.game.is_current_user_joined || this.game.competition_type !== 3)
                    );
                }
                if (getGameLeaderboard) {
                    if (this.rankingMenuActiveKey !== 'top' && this.shouldRenderCompactLeaderBoard(this.game)) {
                        this.getFullLeaderBoard(this.game, GameLeaderboardViewEnum.COMPACT, true);
                    } else {
                        this.getFullLeaderBoard(this.game, GameLeaderboardViewEnum.DEFAULT, true);
                    }
                }
                if (getCurrentUserBets && this.game.is_current_user_joined && this.game.sport_id !== 999) {
                    this.getUserBets(
                        this.game.game_unique_id,
                        this.authenticationService.currentUser.id,
                        true
                    );
                }
                if (getGameVendorGameParticipations && this.game.is_current_user_joined && this.game.sport_id === 999) {
                    this.getGameVendorGameParticipations(
                        this.game.game_unique_id,
                        this.authenticationService.currentUser.id,
                        true
                    );
                }
            }
        }
    }

    public writeGameInvitation(senderUserUniqueId: string, gameUniqueId: string) {
        if (this.authenticationService.currentUser &&
            gameUniqueId === this.game.game_unique_id &&
            senderUserUniqueId !== this.authenticationService.currentUser.unique_id) {
            this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdUsersSenderUserUniqueIdInviteReceiverUserUniqueIdPut(
                this.tenantService.tenantData.id,
                gameUniqueId,
                senderUserUniqueId,
                this.authenticationService.currentUser.unique_id
            ).pipe(take(1))
            .subscribe({
                next: () => {
                    if (localStorage.getItem('gameInvitation-' + gameUniqueId)) {
                        localStorage.removeItem('gameInvitation-' + gameUniqueId);
                    }
                },
                error: (err: HttpErrorResponse) => this.errorService.handleHttpErrorResponse(err)
            });
        }
    }

    public get preGameStartOddsCanChange(): boolean {
        // only if use_initial_outcome is 0 AND game_state is CREATED = 0, PUBLISHED = 1 or RUNABLE = 2
        return (
            this.game &&
            !this.game.use_initial_bet_outcome &&
            this.game.game_state < 3 &&
            this.game.points_engine !== GamePointsEngineEnum.PREDICTION
        );
    }

    public isBetPlacementAllowed(match: MatchHttpResponse): boolean {
        return !(
            (
                this.game.bet_placement_option === GameBetPlacementOptionEnum.ONLY_PRE_GAME && this.game.game_state >= 3
            ) ||
            (
                (match.status === 'live' || (moment(match.season_scheduled_date).isBefore(moment().utc()) && !this.game.is_simulation)) &&
                (this.game.bet_placement_option === GameBetPlacementOptionEnum.ONLY_PRE_MATCH || match.liveodds !== 'booked')
            ) ||
            (
                (match.status !== 'live' || (moment(match.season_scheduled_date).isAfter(moment().utc()) && !this.game.is_simulation)) &&
                this.game.bet_placement_option === GameBetPlacementOptionEnum.ONLY_LIVE
            ) ||
            (
                (match.status !== 'live' || (moment(match.season_scheduled_date).isAfter(moment().utc()) && !this.game.is_simulation)) &&
                (!this.feedService.feedPrematchProducerUp || !this.feedService.feedLiveProducerUp)
            ) ||
            (
                (match.status === 'live' || (moment(match.season_scheduled_date).isBefore(moment().utc()) && !this.game.is_simulation)) &&
                !this.feedService.feedLiveProducerUp
            )
        );
    }

    public processWidgetBetsFromParameters(gameUniqueId: string, widgetParams: string): void {
        this.widgetBetsSubject.next(null);

        const widgetParamsArray = widgetParams.split(',').map(function (widgetParam: string) {
            return widgetParam.split('|');
        });

        const widgetBetObservableArray = widgetParamsArray.map((widgetMatchValues: string[]) => {
            const matchId = +widgetMatchValues[0];
            const marketId = +widgetMatchValues[1];
            const marketSpecifierVal = widgetMatchValues[2];
            const outcomeId = +widgetMatchValues[3];
            const betPoints = +widgetMatchValues[4];

            // check if match exists in game
            const gameMatchExists = this.gameMatchesSubject.value.find((gameMatch: GameMatchHttpResponse) => gameMatch.match_id === matchId);
            // only not started matches
            if (gameMatchExists && gameMatchExists.match &&
                (gameMatchExists.match.status === 'not_started' || gameMatchExists.match.status === 'scheduled')) {
                const defaultMarket = gameMatchExists.match.default_market;
                // check if market is default market
                if (defaultMarket &&
                    defaultMarket.market_id === marketId && defaultMarket.specifier_val === marketSpecifierVal) {
                    const widgetBet: WidgetBet = this.createWidgetBetByMatchMarket(betPoints, gameMatchExists.match, defaultMarket, outcomeId);
                    if (widgetBet) {
                        return of(widgetBet);
                    }
                } else {
                    // no default market
                    // check if markets of match already loaded
                    const currentMatchMarkets = this.matchesMarketsSubject.getValue();
                    if (currentMatchMarkets && currentMatchMarkets[matchId]) {

                        const matchMarketInList = currentMatchMarkets[matchId].find(
                            (matchMarket: MatchMarketHttpResponse) => (matchMarket.market_id === marketId && matchMarket.specifier_val === marketSpecifierVal)
                        );
                        if (matchMarketInList) {
                            const widgetBet: WidgetBet = this.createWidgetBetByMatchMarket(betPoints, gameMatchExists.match, matchMarketInList, outcomeId);
                            if (widgetBet) {
                                return of(widgetBet);
                            }
                        } else {
                            this.debugService.writeMessageToConsoleLog('Widget match ' + matchId + ' - market ' + marketId + ' not exists');
                        }
                    } else {
                        // load market list
                        return this.gamesApi.apiTenantsTenantIdGamesGameUniqueIdMatchesMatchIdMarketsGet(
                            this.tenantService.tenantData.id,
                            gameUniqueId,
                            matchId
                        ).pipe(map((matchMarketList: MatchMarketListHttpResponse) => {
                            if (matchMarketList) {
                                const matchMarketInList = matchMarketList.results.find(
                                    (matchMarket: MatchMarketHttpResponse) => (matchMarket.market_id === marketId && matchMarket.specifier_val === marketSpecifierVal)
                                );
                                if (matchMarketInList) {
                                    const widgetBet: WidgetBet = this.createWidgetBetByMatchMarket(betPoints, gameMatchExists.match, matchMarketInList, outcomeId);
                                    if (widgetBet) {
                                        return widgetBet;
                                    }
                                } else {
                                    this.debugService.writeMessageToConsoleLog('Widget match ' + matchId + ' - market ' + marketId + ' not exists');
                                }
                            }
                            return undefined;
                        }));
                    }
                }
            } else {
                this.debugService.writeMessageToConsoleLog('Widget match ' + matchId + ' does not exist in game');
            }
            return of(undefined);
        });

        forkJoin(widgetBetObservableArray)
            .pipe(take(1))
            .subscribe(
                (widgetBets: WidgetBet[]) => {
                    if (widgetBets && widgetBets.length > 0) {
                        widgetBets = widgetBets.filter((widgetBet: WidgetBet) => widgetBet !== undefined);
                        if (widgetBets && widgetBets.length > 0) {
                            // save to local storage
                            localStorage.setItem('widgetBets-' + this.game.game_unique_id, btoaUnicode(JSON.stringify(widgetBets)));
                        }
                        this.widgetBetsSubject.next(widgetBets);
                    }
                }
            );
    }

    private createWidgetBetByMatchMarket(betPoints: number, match: MatchHttpResponse, matchMarket: MatchMarketHttpResponse, outcomeId: number): WidgetBet {
        // only active markets
        if (matchMarket.market_status === 1) {
            // check if outcome exists
            const outcomeInMarket = matchMarket.outcomes.find((outcome: MatchMarketOutcomeHttpResponse) => outcome.outcome_id === outcomeId);
            if (outcomeInMarket) {
                // only active outcomes
                if (outcomeInMarket.outcome_status === 1) {
                    return {
                        points: +betPoints,
                        match: match,
                        market: matchMarket,
                        outcome: outcomeInMarket
                    };
                } else {
                    this.debugService.writeMessageToConsoleLog('Widget match ' + match.match_id + ' - market ' + matchMarket.market_id + ' - outcome ' + outcomeId + ' not active');
                }
            } else {
                this.debugService.writeMessageToConsoleLog('Widget match ' + match.match_id + ' - outcome ' + outcomeId + ' does not exist in market ' + matchMarket.market_id + ' with specifier ' + matchMarket.specifier_val);
            }
        } else {
            this.debugService.writeMessageToConsoleLog('Widget match ' + match.match_id + ' - market ' + matchMarket.market_id + ' not active');
        }
        return null;
    }

    public get widgetBets(): WidgetBet[] {
        return this.widgetBetsSubject.value;
    }

    public set widgetBets(widgetBets: WidgetBet[]) {
        this.widgetBetsSubject.next(widgetBets);
    }

    public abortRequestsAndUnsubscribeFromBroadcastEvents() {

        this.gameBroadcastEventSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.gameBroadcastEventSubscriptions = [];

        if (this.leaderBoardChangeEventSubscription || this.leaderBoardTimestampChangeEventSubscription) {

            if (this.leaderBoardChangeEventSubscription) {
                this.leaderBoardChangeEventSubscription.unsubscribe();
                this.leaderBoardChangeEventSubscription = null;

                if (this.game) {
                    this.broadcastingService.leaveChannel('GameDetail.' + this.game.game_unique_id + '.Leaderboard');
                }
            }

            if (this.leaderBoardTimestampChangeEventSubscription) {
                this.leaderBoardTimestampChangeEventSubscription.unsubscribe();
                this.leaderBoardTimestampChangeEventSubscription = null;

                if (this.game) {
                    this.broadcastingService.leaveChannel('GameDetail.' + this.game.game_unique_id + '.LeaderboardTimestamp');
                }
            }
        }

        if (this.game) {
            this.broadcastingService.leaveChannel('GameDetail.' + this.game.game_unique_id);
        }

        this.matchBroadcastEventSubscriptions.forEach(gameMatchGroup => {
            this.broadcastingService.leaveChannel('Match.' + gameMatchGroup.matchId);
            gameMatchGroup.subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        });
        this.matchBroadcastEventSubscriptions = [];

        this.gameVendorGameEventSubscriptions.forEach(gameVendorGameGroup => {
            this.broadcastingService.leaveChannel('GameVendorGame.' + gameVendorGameGroup.gameVendorGameId);
            gameVendorGameGroup.subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        });
        this.gameVendorGameEventSubscriptions = [];

        this.apiGetRequestSubscriptions.forEach(subscription => subscription.unsubscribe());
        this.apiGetRequestSubscriptions = [];

        this.gameRequestRunning = false;
        this.gameMatchesRequestRunning = false;
        this.userBetsRequestRunning = false;
        this.leaderBoardRequestRunning = false;
        this.gameVendorGamesRequestRunning = false;
        this.gameVendorGameParticipationsRequestRunning = false;

    }

    public getLeaderBoardUserProperty(game: GameHttpResponse, leaderBoardUser: LeaderboardUserHttpResponse, property: string) {
        if (leaderBoardUser.game_user_group && property === 'is_participation_valid') {
            leaderBoardUser.game_user_group['is_participation_valid'] = leaderBoardUser['is_participation_valid'];
            leaderBoardUser.game_user_group['is_eliminated'] = leaderBoardUser['is_eliminated'];
        }
        return (game.competition_type === 1 || this.isTournament || (game.competition_type === 3 && game.game_state < 3)) ? leaderBoardUser[property] : leaderBoardUser.game_user_group[property];
    }

    public resetAllData() {

        // unsubscribe from all broadcast events
        this.abortRequestsAndUnsubscribeFromBroadcastEvents();

        this.gameShareDataSubject.next(null);
        this.gameMatchesSubject.next(null);
        this.gameVendorGamesSubject.next(null);
        this.matchesMarketsSubject.next([]);
        this.userBetsSubject.next(null);
        this.widgetBetsSubject.next(null);
        this.gameVendorGameParticipationsSubject.next(null);
        this.gameUsersChangedSubject.next(false);
        this.joinedGameListSubject.next(null);
        this.prizeStructureSubject.next(null);
        this.leaderboardUserListSubject.next(null);
        this.leaderboardFriendUserListSubject.next(null);
        this.leaderboardTimestampSubject.next(null);
        this.sentGameInvitationsSubject.next(null);

        this.gameSubject.next(null);

        this._gameSettings = null;
        this.h2hCompetitor = null;

        this.placeBetDialogPendingBets = 0;
        this.placeBetDialogBetPoints = 0.00;

        this.rankingMenuActiveKey = 'all';
    }

    public hasUserBetOnMarketOutcomeSpecifier(matchId: number, marketId: number, specifierValue: string, outcomeId: number = null): boolean {
        if (this.userBetsSubject.value !== null && this.userBetsSubject.value['match' + matchId]) {
            return this.userBetsSubject.value['match' + matchId].find(
                (userBet: UserBetHttpResponse) =>
                    userBet.market_id === marketId &&
                    userBet.specifier_val === specifierValue &&
                    userBet.outcome_id === (outcomeId ? outcomeId : userBet.outcome_id)
            ) !== undefined;
        }

        return false;
    }

    private getBattleRoyalCompactLeaderboard(gameLeaderboardUserHttpResponses: LeaderboardUserHttpResponse[]) {
        // Total Users, has to be even, so that the balance limit can be calculated easily
        const totalUsers = 6;
        // In best case balanced with 3 Users in the Race and 3 Users out of the Race
        const balanceLimit = totalUsers / 2;

        const usersPoolInTheRace = [];
        const usersPoolOutOfTheRace = [];

        const newLeaderboardUserList = [];
        let skipEvaluation = false;
        gameLeaderboardUserHttpResponses.forEach((leaderboardUser: LeaderboardUserHttpResponse) => {
            if (!skipEvaluation) {
                // we have reached the invalid users, we can stop searching
                if (leaderboardUser.is_participation_valid === false) {
                    skipEvaluation = true;
                    return;
                }

                // if we have reached at least $totalUsers (eg: 6) users with min. $balanceLimit (eg: 3) users_out_of_the race we can stop
                if (usersPoolOutOfTheRace.length >= balanceLimit && (usersPoolOutOfTheRace.length + usersPoolInTheRace.length >= totalUsers)) {
                    skipEvaluation = true;
                    return;
                }

                // we have reached the at least $totalUsers (eg: 6) users out of the race, we have enough
                if (usersPoolOutOfTheRace.length >= totalUsers) {
                    skipEvaluation = true;
                    return;
                }

                // In the Race Users, top $totalUsers (eg: 6)
                if (usersPoolInTheRace.length < totalUsers && leaderboardUser.is_eliminated === false && leaderboardUser.is_participation_valid === true) {
                    usersPoolInTheRace.push(leaderboardUser);
                    return;
                }

                // Out of the Race Users, top $balanceLimit (eg: 3) or enough to fill up for a total of $totalUsers (eg: 6) users
                if ((usersPoolOutOfTheRace.length < balanceLimit || (usersPoolOutOfTheRace.length + usersPoolInTheRace.length < totalUsers)) && leaderboardUser.is_eliminated === true && leaderboardUser.is_participation_valid === true) {
                    usersPoolOutOfTheRace.push(leaderboardUser);
                }
            }
        });

        const usersCountOutOfTheRace = (usersPoolOutOfTheRace.length > balanceLimit) ? balanceLimit : usersPoolOutOfTheRace.length;
        const usersCountInTheRace = totalUsers - usersCountOutOfTheRace;

        // Add users in the race to the Leaderboard
        for (let i = 0; i < usersCountInTheRace; i++) {
            if (usersPoolInTheRace[i] !== undefined) {
                newLeaderboardUserList.push(usersPoolInTheRace[i]);
            }
        }

        // Add users out of the race to the Leaderboard
        for (let i = 0; i < usersCountOutOfTheRace; i++) {
            if (usersPoolOutOfTheRace[i] !== undefined) {
                newLeaderboardUserList.push(usersPoolOutOfTheRace[i]);
            }
        }

        return newLeaderboardUserList;
    }
}
