import {Component, Inject, Injector, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DOCUMENT, Location} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {
    AuthenticationService,
    DbTranslationsPipe,
    DebugService,
    ErrorService,
    GlobalError,
    HintService,
    MyModalService,
    MyTranslateService,
    OneSignalService,
    TenantImageTypeEnum,
    TenantService, UrlService,
    WindowRef
} from './shared';
import {
    AppHttpResponsesMediaMediaHttpResponse as MediaHttpResponse,
    AppHttpResponsesTranslationsMediaTranslationHttpResponse as MediaTranslationHttpResponse,
    AppHttpResponsesUsersPlayerHttpResponse as PlayerHttpResponse,
    AppHttpResponsesTenantsTenantHintHttpResponse as TenantHintHttpResponse,
    AppHttpResponsesTenantsTenantAdvertisementListHttpResponse as TenantAdvertisementListHttpResponse
} from './api';
import {filter, pairwise, take, takeWhile} from 'rxjs/operators';
import {ModalSize, ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';
import {SwiperComponent} from '@duxor/ngx-useful-swiper';
import {SwiperOptions} from 'swiper';
import {Subscription} from 'rxjs';

export interface AlertModalContext {
    title: string;
    text: string;
}

@Component({
    selector: 'betsnaps-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    private currentUser: PlayerHttpResponse;

    private _routeScrollPositions: { [url: string]: number } = {};

    public initialLoadFinished: boolean = false;
    public withBgImg: boolean = false;
    public withMaintenanceBgImg: boolean = false;
    public isWidget: boolean = false;

    public globalError: GlobalError = null;

    private nativeWindow;

    private titleTranslations: Object;

    private unreadUserHintsLoadedSubscription: Subscription;
    public unreadGeneralHints: TenantHintHttpResponse[];
    public generalHintsOverlayVisible: boolean = false;
    public generalHintsProcessing: boolean = false;
    private generalHintsModalVisible = false;

    public hintSwiperInit: boolean = false;
    public hintSwiperConfig: SwiperOptions = {
        pagination: {
            el: '.hints-swiper-bottom-pagination',
            clickable: false
        },
        spaceBetween: 0,
        loop: false,
        simulateTouch: true,
        on: {
            afterInit: () => {
                this.checkSwiper();
            }
        }
    };

    private preLoadedImageIds = [];

    private previousUrl: string = null;
    private currentUrl: string = null;

    @ViewChild('hintSwiper') hintSwiper: SwiperComponent;

    @ViewChild('errorModalTemplate', { static: true }) public errorModalTemplate: ModalTemplate<null, string, string>;
    @ViewChild('alertModalTemplate', { static: true }) public alertModalTemplate: ModalTemplate<AlertModalContext, string, string>;
    @ViewChild('generalHintsModalTemplate', { static: true }) public generalHintsModalTemplate: ModalTemplate<null, string, string>;

    private globalErrorModalOpened = false;

    private cssCacheTimestamp: string = '20241115121200';

    constructor(@Inject(DOCUMENT) private document: any,
                private activatedRoute: ActivatedRoute,
                private authenticationService: AuthenticationService,
                private debugService: DebugService,
                private errorService: ErrorService,
                private injector: Injector,
                private metaService: Meta,
                private myModalService: MyModalService,
                private myTranslateService: MyTranslateService,
                private oneSignalService: OneSignalService,
                private tenantService: TenantService,
                private hintService: HintService,
                private titleService: Title,
                private translateService: TranslateService,
                private router: Router,
                private windowRef: WindowRef,
                private location: Location,
                private renderer: Renderer2,
                private urlService: UrlService
    ) {
        if (this.debugService.doDebug) {
            this.debugService.writeMessageToConsoleLog(environment);
        }

        this.nativeWindow = windowRef.nativeWindow;

        // set default lang
        this.translateService.setDefaultLang('en');

        // save or restore scroll position on route change
        this.router.events.pipe(
                filter(routerEvent => (routerEvent instanceof NavigationStart || routerEvent instanceof NavigationEnd)),
                pairwise()
            ).subscribe(([prevRouteEvent, currRouteEvent]) => {
                if (prevRouteEvent instanceof NavigationEnd && currRouteEvent instanceof NavigationStart) {
                    this._routeScrollPositions[prevRouteEvent.url] = Math.floor(
                        this.nativeWindow.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0
                    );
                }
                if (currRouteEvent instanceof NavigationEnd) {
                    this.nativeWindow.window.scrollTo(0, this._routeScrollPositions[currRouteEvent.url] || 0);

                    // if valid token exists but no current user
                    if (this.initialLoadFinished && this.authenticationService.validToken() && !this.authenticationService.currentUser) {
                        // reinitialize user
                        this.authenticationService.setAuthTokenFromStorage();
                        this.authenticationService.getCurrentUser()
                            .pipe(take(1))
                            .subscribe({
                                next: (userInfo: PlayerHttpResponse) => {
                                    // set current user
                                    if (userInfo) {
                                        this.currentUser = userInfo;
                                        this.authenticationService.setCurrentUser(userInfo);
                                    } else {
                                        this.authenticationService.localLogout();
                                    }
                                },
                                error: () => {
                                    this.authenticationService.localLogout();
                                }
                            });
                    }
                }
        });

        // save previous url
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
            this.urlService.setPreviousUrl(this.previousUrl);
        });

        this.errorService.modalError$.subscribe(
            () => {
                this.openErrorModal();
            }
        );
        this.errorService.modalAlert$.subscribe(
            (alertKey: string) => {
                if (alertKey) {
                    this.openAlertModal(alertKey);
                }
            }
        );
        this.errorService.globalError$.subscribe(
            (globalError: GlobalError) => {
                if (globalError) {
                    this.globalError = globalError;
                    if (globalError.errorResponse.status === 0) {
                        this.openErrorModal();
                    }
                }
            }
        );
    }

    ngOnInit() {
        // get tenant data
        this.tenantService.loadTenantDataForDomain();
        this.tenantService.allTenantDataLoaded$.subscribe(
            (tenantDataLoaded: boolean) => {
                if (tenantDataLoaded === true) {
                    if (this.tenantService.isMaintenanceMode) {
                        // site is in maintenance mode
                        const maintenanceRedirectUrl = this.location.path();
                        if (maintenanceRedirectUrl !== '/' && maintenanceRedirectUrl !== '/maintenance') {
                            localStorage.setItem('maintenanceRedirect', maintenanceRedirectUrl);
                        }
                        this.setInitialData(true);
                        this.router.navigate(['/maintenance']);
                        return;

                    } else {
                        // initialize user
                        this.authenticationService.getCurrentUser()
                            .pipe(take(1))
                            .subscribe({
                                next: (userInfo: PlayerHttpResponse) => {
                                    this.debugService.writeMessageToConsoleLog('User');
                                    this.debugService.writeMessageToConsoleLog(userInfo);

                                    // set current user
                                    if (userInfo) {
                                        this.currentUser = userInfo;
                                        this.authenticationService.setCurrentUser(userInfo);
                                    }
                                    this.setInitialData();

                                },
                                error: () => {
                                    this.authenticationService.localLogout(false);
                                    this.setInitialData();
                                }
                            });
                    }
                }
            }
        );
    }

    setInitialData(maintenance: boolean = false) {
        // set language and translations
        this.authenticationService.languageInit();
        this.myTranslateService.loadTranslations();

        // wait till translations are loaded
        this.myTranslateService.translationsLoaded$
            .subscribe((translationsLoaded: boolean) => {
                if (translationsLoaded) {
                    this.debugService.writeMessageToConsoleLog('Translations loaded');

                    this.setBrowserTitle();
                    // set browser title on every route NavigationEnd event
                    this.router.events.pipe(
                        filter(event => event instanceof NavigationEnd)
                    ).subscribe(() => {
                        this.setBrowserTitle();
                    });
                    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
                        this.setBrowserTitle();
                    });

                    this.setMetaData();
                    this.setTheme(maintenance);
                    this.loadAdditionalScripts();

                    if (this.currentUser) {
                        this.showGeneralHints(this.currentUser);
                    }

                    // get current user updates
                    this.authenticationService.currentUser$.subscribe(
                        (userInfo: PlayerHttpResponse) => {
                            if (userInfo) {
                                if (!this.currentUser && !this.unreadGeneralHints) {
                                    this.showGeneralHints(userInfo);
                                }
                            } else {
                                if (this.unreadUserHintsLoadedSubscription) {
                                    this.unreadUserHintsLoadedSubscription.unsubscribe();
                                }
                                this.unreadGeneralHints = null;
                            }
                            this.currentUser = userInfo;
                        });
                }
            });
    }

    private setBrowserTitle() {
        this.titleTranslations = this.myTranslateService.pageTitleTranslations;

        let title = this.tenantService.tenantData.seo.meta_title || this.tenantService.tenantData.name;
        let route = this.activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
            // get translation
            if (this.titleTranslations &&
                route.snapshot.data['translateKey'] &&
                this.titleTranslations[route.snapshot.data['translateKey']]
            ) {
                title = title + ' - ' + this.titleTranslations[route.snapshot.data['translateKey']];
            } else {
                title = (route.snapshot.data['title']) ? (title + ' - ' + route.snapshot.data['title']) : title;
            }
            this.withBgImg = route.snapshot.data['withBgImg'] || false;
            this.withMaintenanceBgImg = route.snapshot.data['withMaintenanceBgImg'] || false;
            this.isWidget = route.snapshot.data['isWidget'] || false;
        }

        this.titleService.setTitle(title);
        this.metaService.updateTag({
            content: title,
            name: 'title'
        });
        this.metaService.updateTag({
            content: this.tenantService.tenantData.seo.meta_title || '',
            name: 'apple-mobile-web-app-title'
        });
        this.metaService.updateTag({
            content: this.tenantService.tenantData.seo.meta_title || '',
            property: 'og:title'
        });
        this.metaService.updateTag({
            content: this.tenantService.tenantData.seo.meta_description || '',
            name: 'description'
        });
        this.metaService.updateTag({
            content: this.tenantService.tenantData.seo.meta_description || '',
            property: 'og:description'
        });
        this.metaService.updateTag({
            content: '',
            property: 'og:url'
        });
        this.metaService.updateTag({
            content: '',
            property: 'og:image'
        });
    }

    private setMetaData() {
        // set application names
        this.metaService.updateTag({
            content: this.tenantService.tenantData.name,
            name: 'application-name'
        });
        this.metaService.updateTag({
            content: this.tenantService.tenantData.name,
            name: 'short-name'
        });
        this.metaService.updateTag({
            content: this.tenantService.tenantData.name,
            name: 'apple-mobile-web-app-title'
        });

        // set colors
        this.metaService.updateTag({
            content: this.tenantService.tenantData.configuration.color_background,
            name: 'theme-color'
        });
        this.metaService.updateTag({
            content: this.tenantService.tenantData.configuration.color_background,
            name: 'background-color'
        });
        this.metaService.updateTag({
            content: this.tenantService.tenantData.configuration.color_background,
            name: 'msapplication-TileColor'
        });

        // set favicon
        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.FAVICON)) {
            this.document.getElementById('favicon_ico').href = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.FAVICON).media_url;
            this.document.getElementById('favicon_ico').disabled = false;
        }
        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.FAVICON_PNG)) {
            this.document.getElementById('favicon_png').href = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.FAVICON_PNG).media_url;
            this.document.getElementById('favicon_png').disabled = false;
        }

        // set app icon
        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.APPICON)) {
            for (const elem of this.document.getElementsByClassName('header_appicon')) {
                elem.href = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.APPICON).media_url;
                elem.disabled = false;
            }
            this.metaService.updateTag({
                content: this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.APPICON).media_url,
                name: 'msapplication-TileImage'
            });
        }
    }

    private setTheme(maintenance: boolean = false) {
        const cssStyleElement = this.document.getElementById('theme_css');

        if (this.tenantService.tenantData.configuration.theme) {
            cssStyleElement.href = './assets/css/themes/' + this.tenantService.tenantData.configuration.theme.name + '/semantic.min.css?ts=' + this.cssCacheTimestamp;
        } else {
            cssStyleElement.href = './assets/css/themes/_default/semantic.min.css?ts=' + this.cssCacheTimestamp;
        }
        this.document.getElementById('theme_css').disabled = false;

        // wait until theme css has been loaded
        const _this = this;
        cssStyleElement.onload = function () {
            _this.document.getElementById('preload_css').disabled = true;
            _this.finishInitialLoad(maintenance);
        };
    }

    private loadAdditionalScripts() {

        // check for bugreporter
        if (environment.bugreporter && !this.isWidget) {
            const bugreportScript = this.document.createElement('script');
            bugreportScript.type = 'text/javascript';
            bugreportScript.src = 'https://betting-solutions.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-w0bwo4/b/14/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=b00ea6d4';
            this.document.head.appendChild(bugreportScript);
        }

        if (!this.nativeWindow.window.BetSnapMobileWrapper && this.oneSignalService.webPushAvailableForTenant) {
            // activate web push
            const oneSignalManifest = this.document.createElement('link');
            oneSignalManifest.rel = 'manifest';
            oneSignalManifest.href = './manifest.json';
            this.document.head.appendChild(oneSignalManifest);

            const oneSignalScript = this.document.createElement('script');
            oneSignalScript.type = 'text/javascript';
            oneSignalScript.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
            oneSignalScript.async = true;

            // initialize onesignal service after sdk script is loaded
            oneSignalScript.onload = () => {
                this.debugService.writeMessageToConsoleLog('OneSignal: sdk script loaded');
                this.oneSignalService.init();
            };
            this.document.head.appendChild(oneSignalScript);
        }

    }

    private finishInitialLoad(maintenance: boolean = false) {
        if (!this.initialLoadFinished) {
            if (this.nativeWindow.window.BetSnapMobileWrapper) {
                this.nativeWindow.window.BetSnapMobileWrapper.loadFinished([]);
            }
            if (maintenance) {
                this.initialLoadFinished = true;
            } else {
                // Tenant Ads have to be loaded before we set initialLoadFinished to true
                this.tenantService.tenantAdvertisements$.pipe(takeWhile(() => !this.initialLoadFinished))
                    .subscribe({
                        next: (tenantAds: TenantAdvertisementListHttpResponse) => {
                            if (tenantAds) {
                                this.initialLoadFinished = true;
                            }
                        },
                        error: () => {
                            // On Error finish initial load without ads
                            this.initialLoadFinished = true;
                        }
                    });
            }
        }
    }

    private openErrorModal() {
        if (this.initialLoadFinished && !this.globalErrorModalOpened) {

            const config = new TemplateModalConfig<null, string, string>(this.errorModalTemplate);
            config.size = ModalSize.Large;
            config.isBasic = true;
            config.isClosable = false;

            this.globalErrorModalOpened = true;
            this.myModalService.openModal(config).onApprove(() => {
                this.globalErrorModalOpened = false;
            }).onDeny(() => {
                this.globalErrorModalOpened = false;
            });

        }
    }

    private openAlertModal(alertKey: string) {
        const config = new TemplateModalConfig<AlertModalContext, string, string>(this.alertModalTemplate);
        this.translateService.get(['ALERTS.title', 'ALERTS.' + alertKey])
            .pipe(take(1)).subscribe(
                translation => {
                    config.context = {
                        title: translation['ALERTS.title'],
                        text: translation['ALERTS.' + alertKey]
                    };
                    config.size = ModalSize.Large;
                    config.isBasic = true;
                    this.myModalService.openModal(config);
                });
    }

    private openGeneralHintsModal() {
        if (!this.generalHintsModalVisible) {
            const config = new TemplateModalConfig<null, string, string>(this.generalHintsModalTemplate);
            config.size = ModalSize.Tiny;
            config.isClosable = false;

            this.generalHintsModalVisible = true;
            this.myModalService.openModal(config).onApprove(() => {
                this.readGeneralHints();
                this.generalHintsModalVisible = false;
            }).onDeny(() => {
                this.generalHintsModalVisible = false;
            });
        }
    }

    private showGeneralHints(userInfo: PlayerHttpResponse) {
        // wait till unread general hints are loaded
        if (userInfo) {
            this.unreadUserHintsLoadedSubscription = this.hintService.unreadUserHintsLoaded$.subscribe(
                (unreadUserHintsLoaded: boolean) => {
                    if (unreadUserHintsLoaded === true) {
                        this.unreadGeneralHints = this.hintService.unreadGeneralUserHints;
                        if (this.unreadGeneralHints.length > 0) {
                            this.unreadGeneralHints = this.unreadGeneralHints.map(
                                (hint: TenantHintHttpResponse) => {
                                    // translate hint texts
                                    hint.title = new DbTranslationsPipe(this.authenticationService).transform(hint.title, 'title', hint.translations);
                                    hint.description = new DbTranslationsPipe(this.authenticationService).transform(hint.description, 'description', hint.translations);

                                    // preload default media image
                                    if (hint.media && !this.preLoadedImageIds.includes(hint.media.media_id)) {
                                        const preLoadMedia = new Image();
                                        preLoadMedia.src = hint.media.media_url;
                                        this.preLoadedImageIds.push(hint.media.media_id);
                                    }

                                    // preload media translation images
                                    if (hint.media_translations && hint.media_translations.length > 0) {
                                        hint.media_translations.forEach(
                                            (hintMediaTranslation: MediaTranslationHttpResponse) => {
                                                if (hintMediaTranslation.media && !this.preLoadedImageIds.includes(hintMediaTranslation.media.media_id)) {
                                                    const preLoadMedia = new Image();
                                                    preLoadMedia.src = hintMediaTranslation.media.media_url;
                                                    this.preLoadedImageIds.push(hintMediaTranslation.media.media_id);
                                                }
                                            }
                                        );
                                    }
                                    return hint;
                                }
                            );

                            if (this.unreadGeneralHints.length > 1) {
                                // init swiper with delay as slides must be created in DOM before swiper initialization
                                setTimeout(() => {
                                    this.hintSwiperInit = true;
                                }, 100);
                            }

                            if (this.tenantService.componentTemplateToLoad === 'v3') {
                                this.openGeneralHintsModal();
                            } else {
                                this.generalHintsOverlayVisible = true;
                                this.renderer.addClass(this.document.documentElement, 'noscroll');
                            }
                        }
                    }
                }
            );
        }
    }

    public hintTrackBy(index: number, hint: TenantHintHttpResponse): number {
        return hint.id;
    }

    public readGeneralHints() {
        if (this.generalHintsOverlayVisible || this.generalHintsModalVisible) {
            if (this.authenticationService.currentUser) {
                this.generalHintsProcessing = true;

                const masterHintIdsToRead: number[] = [];
                this.unreadGeneralHints.forEach((hint: TenantHintHttpResponse) => {
                    masterHintIdsToRead.push(hint.master_hint.id);
                });

                this.hintService.markHintsAsRead(masterHintIdsToRead)
                    .pipe(take(1))
                    .subscribe({
                        next: () => {
                            this.generalHintsProcessing = false;
                            this.closeHintsOverlay();
                        },
                        error: (err: HttpErrorResponse) => {
                            this.generalHintsProcessing = false;
                            this.errorService.handleHttpErrorResponse(err);
                        }
                    });
            } else {
                this.closeHintsOverlay();
            }
        }
    }

    public closeHintsOverlay() {
        this.generalHintsOverlayVisible = false;
        this.renderer.removeClass(this.document.documentElement, 'noscroll');
        this.nativeWindow.window.scrollTo(0, 0);
    }

    public getHintMediaTranslationForLanguage(hint: TenantHintHttpResponse): MediaHttpResponse {
        let media = null;

        if (hint.media) {
            media = hint.media;
        }
        if (hint.media_translations && hint.media_translations.length > 0) {
            const mediaForLanguage = hint.media_translations.find((hintMediaTranslation: MediaTranslationHttpResponse) => (hintMediaTranslation.iso_code2 === this.authenticationService.currentLang.iso_code2 && hintMediaTranslation.label_field === 'media_id'));
            if (mediaForLanguage) {
                media = mediaForLanguage.media;
            }
        }
        return media;
    }

    reloadPage() {
        this.nativeWindow.location.reload();
        return false;
    }

    // help function to determine the available swiper steps because of bugged pagination rendering in swiper plugin
    public getSwiperSlidesIndexArray(slidesLength: number) {
        return Array.from(Array(slidesLength).keys());
    }

    // check if swiper slides have been created after initialization (needed as slider sometimes initializes before slides have been created in DOM)
    private checkSwiper() {
        setTimeout(() => {
            if (!this.hintSwiper.swiper) {
                this.checkSwiper();
            } else if (this.hintSwiper.swiper.slides.length === 0) {
                this.hintSwiper.swiper.update();
            }
        }, 250);
    }
}
